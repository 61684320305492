<template>
  <div
    class="form-group"
    :class="{ className, 'has-errors' : errors.length }">
    <label :for="name">
      <slot></slot>
    </label>

    <input
      :id="name"
      :name="name"
      :type="type"
      :value="value"
      :maxlength="maxLength >= 0 ? maxLength : null"
      :autocomplete="autocomplete"
      class="form-control"
      :class="{ 'secondary' : secondaryStyle }"
      @input="$emit('input', $event.target.value)">

    <div
      v-if="showLengthIndicator && maxLength >= 0"
      class="text-right small">
      {{ inputValueLength }}/{{ maxLength }}
    </div>

    <app-form-errors :input-errors="errors" />
  </div>
</template>

<script>
  import AppFormErrors from './AppFormErrors.vue';

  export default {
    components: {
      AppFormErrors,
    },
    props: {
      name: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Number],
        default: null,
      },
      errors: {
        type: Array,
        default: () => [],
        required: true,
      },
      className: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      maxLength: {
        type: Number,
        default: -1,
      },
      autocomplete: {
        type: String,
        default: 'on',
      },
      secondaryStyle: {
        type: Boolean,
        default: false,
      },
      showLengthIndicator: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      inputValueLength() {
        return this.value === null ? 0 : (`${this.value}`).length;
      },
    }
  };
</script>
