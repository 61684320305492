<template>
  <div class="card">
    <div class="card-body">
      <form @submit.prevent="submit">
        <div class="form-group">
          <label for="query-search">Zoeken:</label>
          <input
            id="query-search"
            v-model="query.search"
            type="text"
            placeholder="Naam, organisator"
            class="form-control secondary">
        </div>

        <div
          class="form-group">
          <label for="start_date">Datum van:</label>
          <input
            id="start_date"
            v-model="query.start_date"
            type="date"
            class="form-control secondary">
        </div>
        <div
          class="form-group">
          <label for="end_date">Datum tot:</label>
          <input
            id="end_date"
            v-model="query.end_date"
            type="date"
            class="form-control secondary">
        </div>

        <app-places
          ref="places"
          :errors="errors"
          :input-class="'form-control secondary'"
          @onlocationselect="onLocationSelect"
          @onclear="onLocationClear" />

        <div
          class="form-group">
          <label for="radius">Binnen een straal van:</label>
          <select
            id="radius"
            v-model="query.radius"
            class="secondary browser-default custom-select">
            <option
              value=""
              selected>
              Alle afstanden
            </option>
            <option value="25000">
              25km
            </option>
            <option value="50000">
              50km
            </option>
            <option value="75000">
              75km
            </option>
            <option value="100000">
              100km
            </option>
            <option value="200000">
              200km
            </option>
            <option value="500000">
              500km
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="category">Categorie:</label>
          <select
            id="category"
            v-model="query.category_id"
            class="secondary browser-default custom-select">
            <option
              value=""
              selected>
              Alle categorieën
            </option>
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label class="w-100">
            Status:
          </label>

          <div class="custom-control custom-radio custom-control-inline">
            <input
              id="future"
              v-model="query.archive"
              type="radio"
              value="future"
              class="custom-control-input">
            <label
              class="custom-control-label"
              for="future">
              Toekomstig
            </label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input
              id="archived"
              v-model="query.archive"
              type="radio"
              value="archived"
              class="custom-control-input">
            <label
              class="custom-control-label"
              for="archived">
              Verleden
            </label>
          </div>
        </div>

        <button
          type="submit"
          class="btn"
          :disabled="isLoaded"
          >
          Filters toepassen <div :class="[ isLoaded ? 'loader spinner-border text-light' : 'hideLoader' ]"></div>
        </button>

      </form>
    </div>
  </div>
</template>

<script>
  import AppPlaces from '../../../components/AppPlaces.vue';

  export default {
    components: { AppPlaces },

    props: {
      query: {
        type: Object,
        required: true,
      },

      errors: {
        type: Object,
        default: () => {},
      },
      isLoaded: {
        type: Boolean,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
    },

    mounted() {
      if (this.$refs.places) {
        this.$refs.places.initPlaces();
      }
    },

    methods: {
      submit() {
        this.$emit('onsubmit');
      },

      onLocationClear() {
        this.query.city = '';
        this.query.lat = 0;
        this.query.lng = 0;
      },

      onLocationSelect(data) {
        this.query.city = _.get(data, 'city', '');
        this.query.lat = _.get(data, 'lat', 0);
        this.query.lng = _.get(data, 'lng', 0);
      },
    },
  };
</script>
