<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Gebruiker aanmaken</h2>

        <app-form-create
          ref="formCreate"
          :submit-form="createUser"
          :errors="errors"
          :clients="clients"
          :roles="roles"
          :new-user="newUser" />
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../api';
  import AppFormCreate from '../components/AppFormCreate.vue';

  export default {
    components: {
      AppFormCreate,
    },
    data() {
      return {
        errors: {},
        clients: [],
        roles: [],
        newUser: {
          role: 'client',
          send_invitation: 1,
          client_id: null,
        },
      };
    },

    created() {
      this.getClients();
      this.getRoles();
    },

    methods: {
      async createUser() {
        try {
          const formData = new FormData();

          for (const key in this.newUser) {
            if (key) {
              formData.append(key, this.newUser[key]);
            }
          }

          const { data } = await api.createUser(formData);

          this.errors = {};
          this.$toast.success('Gebruiker is aangemaakt', 'Ok');

          if (this.newUser.send_invitation === 1) {
            const userId = data.data.id;

            if (userId !== null) {
              await api.inviteUser(userId);
              this.$toast.success('Uitnodiging is verstuurd naar de gebruiker', 'Ok');
            }
          }

          this.newUser = {
            role: 'client',
            send_invitation: 1,
            client_id: this.clients[0].id,
          };

          await this.$router.push({
            name: 'users.index',
          });
        } catch (error) {
          this.errors = error.response.data.errors;
        }
      },

      async getClients() {
        const { data } = await api.getClients({ params: { noPagination: true } });
        this.clients = data.data;
        this.newUser.client_id = this.clients[0].id;
      },

      async getRoles() {
        const { data } = await api.getAvailableRoles();
        this.roles = data;
      },
    },
  };
</script>
