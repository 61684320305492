<template>
  <div>
    <div
      class="row mb-5">
      <div class="col-md-12">
        <h2>Inschrijvingen:</h2>
        <app-registrations :finished="false" />
      </div>
    </div>

    <div
      class="row mb-5">
      <div class="col-md-12">
        <h2>Afgeronde shifts:</h2>
        <app-registrations :finished="true" />
      </div>
    </div>
  </div>
</template>

<script>
  import AppRegistrations from '@modules/Dashboard/components/AppRegistrations.vue';

  export default {
    components: { AppRegistrations },
  };
</script>
