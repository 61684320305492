<template>
  <div>
    <div class="container mt-3 mb-3">
      <div v-for="shift in assignment.assignmentshifts" class="row">
        <div class="col-md-12 mt-5">
          <h3>{{shift.start_datetime_formatted}}, {{shift.time_formatted}}</h3>
          <img :src="shift.qrcode_src" alt="QR Code" class="img-fluid"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../api';

  export default {
    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
      };
    },

    created() {
      console.log(this.assignment);
    },

    methods: {
    },
  };
</script>
