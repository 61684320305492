<template>
  <form @submit.prevent="submitForm">
    <div class="flex mobile-full">
      <!-- LEFT HALF -->
      <div>
        <div class="flex=">
          <div
            :class="{ 'has-errors' : errors.name }"
            class="form-group">
            <label
              for="title">Naam categorie:</label>
            <input
              id="title"
              v-model="fields.name"
              type="text"
              class="form-control">
          </div>
        </div>
    </div>

    <button
      class="btn mt-2">
      Gegevens opslaan
    </button>
    </div>
  </form>
</template>

<script>

  import mixins from '../mixins';
  import api from '../api';


  export default {
    components: {
    },
    mixins: [mixins],
    data() {
      return {
        fields: {
        },
        errors: {},
        categoryId: '',
        category: {},
      };
    },
    created() {
      if (!this.$route.params.categoryId) {
        this.createCategory();
      } else {
        this.categoryId = this.$route.params.categoryId;
        this.getCategoryById();
      }
    },
    methods: {
      submitForm() {
        this.updateCategory();
      },
      async getCategoryById() {
        const { data: { data } } = await api.getCategoryById(this.categoryId);
        this.fields = {
          name: data.name,
        };
        this.category = data;
      },

      async createCategory() {
        try {
          const { data } = await api.createCategory();
          this.categoryId = _.get(data, 'data.id', null);
          this.getCategoryById();
          this.errors = {};
        } catch (e) {
          this.errors = e.response.data.errors;
          this.$toast.error('Er is iets misgegaan', 'Oops');
        }
      },
      async updateCategory() {
        try {
          const formData = new FormData();

          for (const key in this.fields) {
            if (this.fields[key]) {
              formData.append(key, this.fields[key]);
            }
          }

          formData.append('_method', 'PUT');

          const response = await api.updateCategory(this.categoryId, formData);
          this.errors = {};
          this.$toast.success('Categorie succesvol bijgewerkt');

          await this.$router.push({ name: 'categories.index'});
        } catch (error) {
          this.errors = error.response.data.errors;
          this.$toast.error('Er is iets misgegaan', 'Oops');
        }
      },
    },
  };
</script>
