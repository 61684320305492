<template>
  <div>
    <sub-navigation>
      <li
        v-if="this.$root.$data.user.is_client_or_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'clients.index' }"
          class="sub-nav__link">
          Opdrachtgevers
        </router-link>
      </li>

      <li
        v-if="this.$root.user.is_client_or_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'users.index' }"
          class="sub-nav__link">
          Gebruikers
        </router-link>
      </li>

      <li
        v-if="$root.$data.user.is_client_or_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'assignments.index' }"
          class="sub-nav__link">
          Mijn opdrachten
        </router-link>
      </li>

      <li
        v-if="this.$root.user.is_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'categories.index' }"
          class="sub-nav__link">
          Categorieën
        </router-link>
      </li>

      <li
        class="sub-nav__list">
        <router-link
          :to="{ name: 'calendar.index' }"
          class="sub-nav__link">
          Kalender
        </router-link>
      </li>

      <li
        v-if="$root.$data.user.is_security"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'assignments.index' }"
          class="sub-nav__link">
          Werk zoeken
        </router-link>
      </li>

      <template
        v-if="this.$root.user.can_create && $route.path === '/users/'"
        v-slot:button>
        <div class="ml-auto">
          <router-link
            :to="{ name: 'users.create' }"
            class="btn absolute-mobile">
            Gebruiker toevoegen
          </router-link>
          <button
            class="btn absolute-mobile"
            @click="toggleModal">
            Gebruikers importeren
          </button>
        </div>
      </template>
    </sub-navigation>
    <import-modal
      :show-modal="showModal"
      @close="toggleModal"
      @closeAndRefresh="closeModalAndRefresh" />
    <main
      :class="{
        'absolute-btn-offset' : this.$root.user.can_create && $route.path === '/users/'
      }">
      <router-view :key="$route.name + ($route.params.userId || '')" />
    </main>
  </div>
</template>

<script>
  import ImportModal from '@modules/Users/components/ImportModal.vue';
  import SubNavigation from '../Layout/SubNav.vue';

  export default {
    components: {
      ImportModal,
      SubNavigation,
    },
    data() {
      return {
        showModal: false,
      };
    },

    methods: {
      toggleModal() {
        this.showModal = !this.showModal;
      },
      closeModalAndRefresh() {
        this.showModal = false;
        this.$router.go();
      },
    },
  };
</script>
