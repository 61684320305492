import View from '../View.vue';

/* eslint-disable */
import { Create, Edit, Show, Index } from '../pages/*';
/* eslint-enable */

export default [
  {
    path: '/categories',
    component: View,
    children: [
      {
        path: '',
        name: 'categories.index',
        component: Index,
      },
      {
        path: 'create',
        name: 'categories.create',
        component: Create,
      },
      {
        path: ':categoryId/edit',
        name: 'categories.edit',
        component: Edit,
      },
    ],
  },
];
