<template>
  <form>
    <div class="row">
      <div
        v-for="(securityPass, index) in securityPasses"
        :key="securityPass.id"
        class="col-md-4">
        <security-pass
          :security-pass="securityPass"
          :errors="errors"
          :index="index"
          @removeItem="removeItem" />
      </div>

      <div class="col-md-4">
        <div
          class="card card--security-pass has-hover"
          @click.prevent="createNewItem">
          <div class="card-center-header">
            <i class="material-icons">
              add
            </i>
            <h3>Diploma/Pas toevoegen</h3>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <button
          class="btn"
          @click.prevent="submitForm($event)">
          Diploma's / Passen opslaan
        </button>
      </div>
    </div>
  </form>
</template>

<script>
  import SecurityPass from './SecurityPass.vue';

  export default {
    components: {
      SecurityPass,
    },
    props: {
      securityPasses: {
        type: Array,
        default: () => [],
      },
      submitForm: {
        type: Function,
        required: true,
      },
      errors: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      createNewItem() {
        const newSecurityPass = {};
        const mergedSecurityPasses = [...this.securityPasses, newSecurityPass];
        this.$emit('update:securityPasses', mergedSecurityPasses);
      },
      removeItem(index) {
        this.securityPasses.splice(index, 1);
        this.$emit('update:securityPasses', this.securityPasses);
      },
    },
  };
</script>
