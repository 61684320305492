<template>
  <form @submit.prevent="submitForm">
    <div class="flex-half mobile-full">
      <!-- LEFT HALF -->
      <div>
        <avatar-upload
          v-model="user.avatar"
          :src="user.avatar_src"
          :text="'Avatar'" />

        <div
          v-if="this.$root.user.is_admin && user.is_security"
          class="form-group">
          <label>
            Type:
          </label>
          <div class="form-check pl-0">
            <input-radio
              :id="'type-is-one'"
              v-model="user.securityprofile.securitytype"
              :name="'securitytype'"
              :initial-value="1"
              :checked="hasSecurityType(1)">
              Toezichthouder
            </input-radio>
          </div>
          <div class="form-check pl-0">
            <input-radio
              :id="'type-is-two'"
              v-model="user.securityprofile.securitytype"
              :name="'securitytype'"
              :initial-value="2"
              :checked="hasSecurityType(2)">
              Toezichthouder & Beveiliger
            </input-radio>
          </div>

          <form-errors :input-errors="errors.securitytype" />
        </div>

        <div class="flex-half">
          <input-element
            v-model="user.first_name"
            :errors="errors.first_name">
            Voornaam
          </input-element>

          <input-element
            v-model="user.last_name"
            :errors="errors.last_name">
            Achternaam
          </input-element>
        </div>

        <input-element
          v-model="user.email"
          :name="'email'"
          :type="'email'"
          :errors="errors.email">
          E-mailadres:
        </input-element>

        <input-element
          v-if="user.is_security"
          v-model="user.securityprofile.phone"
          :errors="errors.phone">
          Telefoonnummer:
        </input-element>

        <div
          v-if="user.is_security"
          class="flex-half">
          <input-element
            v-model="user.securityprofile.birthdate"
            :type="'date'"
            :name="'birthdate'"
            :errors="errors.birthdate">
            Geboortedatum:
          </input-element>

          <input-element
            v-model="user.securityprofile.body_length"
            :name="'body_length'"
            :errors="errors.body_length">
            Lengte (cm)
          </input-element>
        </div>

        <div class="form-group">
          <input-checkbox
            :id="'notifications'"
            v-model="user.send_mails"
            :name="'send_mails'"
            :initial-value="1"
            :checked="user.send_mails">
            Ik wil mails ontvangen over nieuwe opdrachten
          </input-checkbox>
        </div>
      </div>

      <!-- RIGHT HALF -->
      <div v-if="user.is_security">
        <input-element
          v-model="user.securityprofile.street"
          :name="'street'"
          :errors="errors.street">
          Straatnaam
        </input-element>

        <div class="flex-half">
          <input-element
            v-model="user.securityprofile.house_number"
            :name="'house_number'"
            :errors="errors.house_number">
            Huisnummer:
          </input-element>
          <input-element
            v-model="user.securityprofile.zip_code"
            :name="'zip_code'"
            :errors="errors.zip_code">
            Postcode:
          </input-element>
        </div>

        <input-element
          v-model="user.securityprofile.city"
          :name="'city'"
          :errors="errors.city">
          Stad:
        </input-element>

        <div class="form-group">
          <label>
            Post voorkeur: (Alle 3 selecteren is hetzelfde als geen selectie)
          </label>
          <div class="custom-control custom-checkbox">
            <input
              id="stand-by"
              value="1"
              type="checkbox"
              class="custom-control-input"
              :checked="hasPostPreferences(1)"
              @change="updatePostPreferencesOptions">
            <label
              for="stand-by"
              class="custom-control-label">
              Stand-by
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              id="visitatie"
              value="2"
              type="checkbox"
              class="custom-control-input"
              :checked="hasPostPreferences(2)"
              @change="updatePostPreferencesOptions">
            <label
              for="visitatie"
              class="custom-control-label">
              Visitatie
            </label>
          </div>
          <div class="custom-control custom-checkbox">
            <input
              id="vaste-post"
              value="3"
              type="checkbox"
              class="custom-control-input"
              :checked="hasPostPreferences(3)"
              @change="updatePostPreferencesOptions">
            <label
              for="vaste-post"
              class="custom-control-label">
              Vaste post
            </label>
          </div>
        </div>

        <div class="flex-half">
          <input-select
            :id="'colbert'"
            v-model="user.securityprofile.size_jacket"
            :selected="user.securityprofile.size_jacket"
            :name="'size_jacket'"
            :data="sizeJackets">
            Maat colbert:
          </input-select>

          <input-select
            :id="'pantalon'"
            v-model="user.securityprofile.size_trousers"
            :selected="user.securityprofile.size_trousers"
            :name="'size_trousers'"
            :data="sizeJackets">
            Maat pantalon:
          </input-select>
        </div>

        <div class="flex-half">
          <input-select
            :id="'kleding'"
            v-model="user.securityprofile.size_clothing"
            :selected="user.securityprofile.size_clothing"
            :name="'size_clothing'"
            :data="sizeClothing">
            Maat kleding:
          </input-select>

          <input-select
            :id="'schoenmaat'"
            v-model="user.securityprofile.size_shoes"
            :selected="user.securityprofile.size_shoes"
            :name="'size_shoes'"
            :data="sizeShoes">
            Schoenmaat:
          </input-select>
        </div>

        <div class="flex-half">
          <div class="form-group">
            <label class="w-100">
              In het bezit van Rijbewijs B:
            </label>
            <input-radio
              :id="'has-drivers-license'"
              v-model="user.securityprofile.has_drivers_license"
              :name="'has_drivers_license'"
              :initial-value="1"
              :checked="hasDriverLicense(1)">
              Ja
            </input-radio>
            <input-radio
              :id="'has-not-drivers-license'"
              v-model="user.securityprofile.has_drivers_license"
              :name="'has_drivers_license'"
              :initial-value="0"
              :checked="hasDriverLicense(0)">
              Nee
            </input-radio>
          </div>

          <div class="form-group">
            <label class="w-100">
              In het bezit van een auto:
            </label>
            <input-radio
              :id="'has-car'"
              v-model="user.securityprofile.has_car"
              :name="'has_car'"
              :initial-value="1"
              :checked="hasCar(1)">
              Ja
            </input-radio>
            <input-radio
              :id="'has-not-car'"
              v-model="user.securityprofile.has_car"
              :name="'has_car'"
              :initial-value="0"
              :checked="hasCar(0)">
              Nee
            </input-radio>
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn mt-2">
      Gegevens opslaan
    </button>
  </form>
</template>

<script>
  import AppFormErrors from '@components/form/AppFormErrors.vue';
  import AppInputElement from '@components/form/AppInputElement.vue';
  import AppInputCheckbox from '@components/form/AppInputCheckbox.vue';
  import AppInputSelect from '@components/form/AppInputSelect.vue';
  import AppInputRadio from '@components/form/AppInputRadio.vue';

  import AppAvatarUpload from '@modules/Clients/components/AppAvatarUpload.vue';
  import mixins from '../mixins';

  import { sizeJackets, sizeShoes, sizeClothing } from '../helpers/sizes';

  export default {
    components: {
      formErrors: AppFormErrors,
      inputElement: AppInputElement,
      inputCheckbox: AppInputCheckbox,
      inputSelect: AppInputSelect,
      inputRadio: AppInputRadio,
      avatarUpload: AppAvatarUpload,
    },
    mixins: [mixins],
    props: {
      submitForm: {
        type: Function,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
      user: {
        type: Object,
        required: true,
      },
      postPreferences: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        sizeJackets,
        sizeClothing,
        sizeShoes,
      };
    },
    methods: {
      updatePostPreferencesOptions(event) {
        if (event.target.checked) {
          this.postPreferences.push(Number(event.target.value));
        } else {
          const index = this.postPreferences.indexOf(Number(event.target.value));
          if (index > -1) {
            this.postPreferences.splice(index, 1);
          }
        }
      },
    },
  };
</script>
