<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Profiel bewerken</h2>

        <app-form
          v-if="loaded"
          :user="user"
          :post-preferences="postPreferences"
          :submit-form="updateUser"
          :errors="errors" />
      </div>
    </div>

    <div
      v-if="user.is_security"
      class="row mt-5">
      <div class="col">
        <h2>Diploma's / Passen</h2>

        <app-form-security-passes
          :security-passes.sync="securityPasses"
          :errors="securityPassErrors"
          :submit-form="updateSecurityPasses" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-6">
        <h2>Wachtwoord wijzigen</h2>

        <app-form-change-password
          :submit-form="updatePassword"
          :errors="passwordErrors" />
      </div>
    </div>
  </div>
</template>

<script>
  import AppForm from '../components/AppForm.vue';
  import AppFormChangePassword from '../components/AppFormChangePassword.vue';
  import AppFormSecurityPasses from '../components/AppFormSecurityPasses.vue';
  import api from '../api';

  export default {
    components: {
      AppForm,
      AppFormChangePassword,
      AppFormSecurityPasses,
    },
    data() {
      return {
        currentUserId: null,
        user: {},
        errors: {},
        passwordErrors: {},
        securityPasses: [],
        securityPassErrors: {},
        postPreferences: [],
        loaded: false,
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        this.currentUserId = this.$route.params.userId;

        await this.getUserById();
        await this.getSecurityPasses();

        this.loaded = true;
      },

      async getUserById() {
        const { data } = await api.getUserById(this.currentUserId);
        this.user = data.data;

        if (this.user.is_security) {
          data.data.securityprofile.postpreferences.forEach(item => {
            this.postPreferences.push(item.id);
          });
        }

        if (!this.user.securityprofile) {
          this.user.securityprofile = {};
        }
      },

      async getSecurityPasses() {
        if (this.user.is_security) {
          const { data } = await api.getSecurityPasses(this.currentUserId);
          this.securityPasses = data;
        }
      },

      async updateUser() {
        const formData = new FormData();
        const securityProfile = this.user.securityprofile;
        const user = this.user;

        for (const key in user) {
          if (user[key] && key !== 'securityprofile') {
            formData.append(key, user[key]);
          }
        }

        if (!formData.has('send_mails')) {
          formData.append('send_mails', 0);
        }

        for (const key in securityProfile) {
          if (securityProfile[key]) {
            formData.append(key, securityProfile[key]);

            if (key === 'postpreferences') {
              if (!this.postPreferences.length) {
                this.postPreferences.push(1, 2, 3);
              }

              for (const value of this.postPreferences) {
                formData.append('postpreferences[]', value);
              }
            }
          }
        }

        formData.append('_method', 'put');

        try {
          const { data } = await api.updateUserById(this.currentUserId, formData);
          this.user = data.data;
          this.errors = {};
          this.$toast.success('Gebruiker is gewijzigd', 'Ok');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = error.response.data.errors;
        }
      },

      async updateSecurityPasses() {
        const formData = {
          securitypasses: this.securityPasses,
        };

        try {
          const { data } = await api.updateSecurityPasses(this.currentUserId, formData);
          this.securityPasses = data || [];
          this.$toast.success('Diploma / Pas is toegevoegd', 'Ok');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.securityPassErrors = error.response.data.errors;
        }
      },

      async updatePassword(event) {
        const form = event.target;
        const formData = new FormData(form);

        try {
          await api.updatePassword(formData);
          this.errors = {};
          form.reset();
          this.$toast.success('Wachtwoord is gewijzigd', 'Ok');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.passwordErrors = error.response.data.errors;
        }
      },
    },
  };
</script>
