<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="row">
        <div class="col-md-3">
          <div class="list-group">
            <a
              v-for="shift in assignmentshifts"
              :key="shift.id"
              class="list-group-item list-group-item-action"
              :class="{ 'active': currentShift && currentShift.id === shift.id }"
              href="#"
              @click.prevent="setCurrentShift(shift)">
              <div>
                <p class="is-bold">{{ shift.date_formatted }}</p>
                <div class="flex-center mt-2 mb-3">
                  <i class="material-icons ac-icon big mr-2">
                    access_time
                  </i>
                  <p>{{ shift.time_formatted }}</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div
                  :class="{ 'has-errors' : errors.reportFile }"
                  class="form-group">
                  <label v-if="$root.$data.user.is_security" for="reportFile">Bestanden uploaden:</label>
                  <label v-if="!$root.$data.user.is_security" for="reportFile">Uploads:</label>

                  <div
                    v-if="fields.currentReportFile"
                    class="alert alert-info mb-2">
                    <a
                      target="_blank"
                      :href="fields.currentReportFile">{{ fields.currentReportName }}</a>

                    <a
                      class="float-right"
                      href="#"
                      @click.prevent="removeCurrentReportFile">Verwijder</a>
                  </div>

                  <div v-if="initialFiles.length > 0" class="alert alert-success">
                    <div v-for="(file, index) in initialFiles" :key="index" class="mb-2">
                      <a
                        target="_blank"
                        :href="file.url">{{ file.name }}</a>

                      <a
                        class="float-right"
                        href="#"
                        @click.prevent="removeInitialFile(file.id)">Verwijder</a>
                    </div>
                  </div>

                  <div v-if="files.length > 0" class="alert alert-info">
                    <div v-for="(file, index) in files" :key="index" class="mb-2">
                      {{ file.name }}
                      <a class="float-right" href="#" @click.prevent="removeFile(index)">Verwijder</a>
                    </div>
                  </div>

                  <div v-if="$root.$data.user.is_security">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click.prevent="openReportFileBrowser">
                      Bestand selecteren
                    </button>
                    <input
                      v-show="false"
                      id="reportFile"
                      ref="reportFile"
                      type="file"
                      multiple
                      @change="selectReportFiles">
                  </div>
                </div>

                <button
                  v-if="$root.$data.user.is_security"
                  type="submit"
                  class="btn mt-5 mb-5">
                  Opslaan
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../api';

  export default {
    props: {
      assignment: {
        type: Object,
        required: true,
      },
      assignmentshifts: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        currentShift: {},
        fields: {},
        errors: {},
        files: [],
        initialFiles: [],
      };
    },
    watch: {
      currentShift: {
        deep: true,
        handler() {
          if (this.$root.$data.user.is_security) {
            this.getPersonalUserReports();
          } else {
            this.getAllUserReports();
          }
        },
      },
      assignmentshifts: {
        deep: true,
        handler() {
          this.initSetCurrentShift();
        },
      },
    },

    methods: {
      initSetCurrentShift() {
        if (this.currentShift && Object.keys(this.currentShift).length > 0) {
          this.setCurrentShift(_.find(this.assignmentshifts, {
            id: this.currentShift.id,
          }));
        } else {
          this.setCurrentShift(_.head(this.assignmentshifts) || {});
        }
        if (this.$root.$data.user.is_security) {
          this.getPersonalUserReports();
        } else {
          this.getAllUserReports();
        }
      },

      async setCurrentShift(shift) {
        this.currentShift = shift;

        this.fields = {
          report: this.currentShift.report,
          currentReportFile: this.currentShift.report_src,
          currentReportName: this.currentShift.report_name,
        };
      },

      async submitForm() {
        if (!this.currentShift) {
          return;
        }

        this.errors = {};
        try {
          const formData = new FormData();

          /* for (const key in this.fields) {
            if (this.fields[key]) {
              formData.append(key, this.fields[key]);
            }
          } */

          this.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });

          await api.updateAssignmentshiftUserReport(this.currentShift.id, formData);

          this.$toast.success('Opgeslagen!', 'Ok');

          this.$emit('updateassignment');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = _.get(error, 'response.data.errors', {});
        }
      },
      async getPersonalUserReports() {
        const { data } = await api.getPersonalUserReports(this.currentShift.id);
        this.initialFiles = data;
      },
      async getAllUserReports() {
        const { data } = await api.getAllUserReports(this.currentShift.id);
        this.initialFiles = data;
      },
      openReportFileBrowser() {
        this.$refs.reportFile.click();
      },

      selectReportFile() {
        this.removeCurrentReportFile();

        const fileData = this.$refs.reportFile.files[0];
        if (fileData) {
          this.fields.reportFile = fileData;
          this.$forceUpdate();
        }
      },

      selectReportFiles() {
        if (this.$refs.reportFile && this.$refs.reportFile.files) {
          this.files = Array.from(this.$refs.reportFile.files);
          this.$forceUpdate();
        } else {
          console.error("File input is not defined.");
        }
      },

      removeCurrentReportFile() {
        this.fields.currentReportFile = null;
        this.fields.currentReportName = null;
      },

      removeReportFile() {
        this.fields.reportFile = null;
        this.$refs.reportFile.value = '';
        this.$forceUpdate();
      },
      removeFile(index) {
        this.files.splice(index, 1); // Remove the file at the given index
        this.$forceUpdate();
      },
      async removeInitialFile(id) {
        try {
          await api.deletePersonalUserReport(id);
          this.$toast.success('Bestand verwijderd!', 'Ok');
          if (this.$root.$data.user.is_security) {
            this.getPersonalUserReports();
          } else {
            this.getAllUserReports();
          }
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
        }

      },
    },
  };
</script>
