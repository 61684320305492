<template>
  <ul
    v-if="inputErrors.length"
    class="input-errors">
    <li
      v-for="(error, index) in inputErrors"
      :key="index">
      {{ optionalRegexCheck(error) }}
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      inputErrors: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
    methods: {
      optionalRegexCheck(value) {
        if (value.includes('securitypasses')) {
          const regex = /securitypasses\.[0-9]+\.([^ .]+)/g;
          return value.replace(regex, '$1');
        }
        return value;
      },
    },
  };
</script>
