<template>
  <div v-if="assignment">
    <form @submit.prevent="submitForm">
      <div class="as-header">
        <div class="as-header__image">
          <img
            :src="assignment.logo_src"
            alt="Logo">
        </div>
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-md-12 d-flex flex-column">
              <div class="form-group mb-auto">
                <button
                  class="btn"
                  @click.prevent="openLogoFileBrowser">
                  Afbeelding wijzigen
                </button>

                <input
                  v-show="false"
                  id="logo"
                  ref="logo"
                  type="file"
                  @change="selectLogoFile('logo')">
              </div>
              <div class="test">
                <div class="flex-half">
                  <div
                    :class="{ 'has-errors' : errors.title }"
                    class="form-group">
                    <label
                      for="title"
                      class="is-white">Naam opdracht:</label>
                    <input
                      id="title"
                      v-model="fields.title"
                      type="text"
                      class="form-control">
                  </div>
                  <app-places
                    ref="places"
                    :errors="errors"
                    :is-white-label="true"
                    @onlocationselect="onLocationSelect"
                    @onclear="onLocationClear" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-8">
            <div
              :class="{ 'has-errors' : errors.description }"
              class="form-group">
              <label for="description">Opdrachtomschrijving:</label>
              <textarea
                id="description"
                v-model="fields.description"
                type="text"
                class="form-control">
            </textarea>
            </div>
            <div
              :class="{ 'has-errors' : errors.minimum_rate }"
              class="form-group">
              <label for="organizer">Minimumtarief:</label>
              <div class="currency-input-wrapper">
                <input
                  id="organizer"
                  v-model="fields.minimum_rate"
                  type="number"
                  class="form-control currency-input">
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="form-group">
              <label for="client_id">
                Opdrachtgever:
              </label>
              <select
                v-if="clients"
                id="client_id"
                v-model="fields.client_id"
                :class="{ 'has-errors' : errors.client_id }"
                name="client_id"
                class="browser-default custom-select">
                <option
                  v-for="client in clients"
                  :key="client.id"
                  :value="client.id">
                  {{ client.name }}
                </option>
              </select>
            </div>

            <div
              :class="{ 'has-errors' : errors.organizer }"
              class="form-group">
              <label for="organizer">Organisator:</label>
              <input
                id="organizer"
                v-model="fields.organizer"
                type="text"
                class="form-control">
            </div>
            <div
              :class="{ 'has-errors' : errors.category}"
              class="form-group">
              <label for="category">Categorie:</label>
              <select
                v-if="categories"
                id="category_id"
                v-model="fields.category_id"
                :class="{ 'has-errors' : errors.category_id }"
                name="category_id"
                class="browser-default custom-select">
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id">
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div
              :class="{ 'has-errors' : errors.briefing }"
              class="form-group">
              <label for="organizer">Opdrachtbriefing uploaden:</label>

              <div
                v-if="fields.briefing"
                class="alert alert-info">
                {{ fields.briefing.name }}

                <a
                  href="#"
                  @click.prevent="removeBriefingFile">Verwijder</a>
              </div>

              <div>
                <button
                  type="button"
                  class="btn btn--secondary"
                  @click.prevent="openBriefingFileBrowser">
                  Bestand selecteren
                </button>
                <input
                  v-show="false"
                  id="briefing"
                  ref="briefing"
                  type="file"
                  @change="selectBriefingFile('briefing')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="container mt-5">
      <div
        v-if="assignmentId"
        class="row">
        <div class="col-md-12">
          <h2>Shifts:</h2>

          <app-shifts :assignment-id="Number(assignmentId)" />

          <div class="form-group">
            <label class="w-100">
              Status:
            </label>

            <div class="custom-control custom-radio custom-control-inline">
              <input
                id="state-published"
                v-model="fields.state"
                type="radio"
                value="3"
                class="custom-control-input">
              <label
                class="custom-control-label"
                for="state-published">
                Gepubliceerd
              </label>
            </div>

            <div class="custom-control custom-radio custom-control-inline">
              <input
                id="state-unpublished"
                v-model="fields.state"
                type="radio"
                value="2"
                class="custom-control-input">
              <label
                class="custom-control-label"
                for="state-unpublished">
                Prive
              </label>
            </div>
          </div>

          <button
            type="submit"
            class="btn mt-5 mb-5"
            @click.prevent="submitForm">
            Opslaan
          </button>

          <a
            class="text-danger ml-2"
            @click.prevent="deleteAssignment">
            Verwijderen
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppShifts from './AppShifts.vue';
  import AppPlaces from '../../../components/AppPlaces.vue';
  import api from '../api';

  export default {
    components: { AppShifts, AppPlaces },

    data() {
      return {
        assignmentId: null,
        assignment: {},
        fields: {},
        errors: {},
        clients: [],
        place: null,
        categories: [],
      };
    },

    created() {
      if (!this.$route.params.assignmentId) {
        this.createAssignment();
      } else {
        this.assignmentId = this.$route.params.assignmentId;
        this.getAssignmentById();
        this.getClients();

        if (!this.fields.client_id) {
          this.fields.client_id = this.$root.$data.user.client_id;
        }
      }
      this.getCategories();
    },

    methods: {
      submitForm() {
        this.updateAssignment();
      },

      async createAssignment() {
        try {
          const { data } = await api.createAssignment();
          this.assignmentId = _.get(data, 'data.id', null);
          this.getAssignmentById();
          this.errors = {};

          this.getClients();

          if (!this.fields.client_id) {
            this.fields.client_id = this.$root.$data.user.client_id;
          }
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = error.response.data.errors;
        }
      },

      async updateAssignment() {
        try {
          const formData = new FormData();

          for (const key in this.fields) {
            if (this.fields[key]) {
              formData.append(key, this.fields[key]);
            }
          }

          formData.append('_method', 'PUT');

          await api.updateAssignmentById(this.assignmentId, formData);
          this.errors = {};
          this.$toast.success('Opdracht is gewijzigd', 'Ok');

          await this.$router.push({
            name: 'assignments.show',
            params: { assignmentId: this.assignmentId },
          });

          this.getAssignmentById();
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = error.response.data.errors;
        }
      },

      async getAssignmentById() {
        const { data: { data } } = await api.getAssignmentById(this.assignmentId);
        this.assignment = data;
        this.fields = {
          assignment_id: data.id,
          client_id: data.client_id,
          title: data.title,
          city: data.city,
          description: data.description,
          organizer: data.organizer,
          state: data.state,
          lat: data.lat,
          lng: data.lng,
          category_id: data.category_id,
          minimum_rate: data.minimum_rate,
        };

        if (this.$refs.places) {
          this.$refs.places.initPlaces();
          this.$refs.places.setVal(this.fields.city);
        }

        if (this.fields.state === 0) {
          this.fields.state = 2;
        }
      },
      async getCategories() {
        const { data: { data } } = await api.getCategories();
        this.categories = data;
      },
      onLocationClear() {
        this.fields.city = '';
        this.fields.lat = '0';
        this.fields.lng = '0';
      },

      onLocationSelect(data) {
        this.fields.city = _.get(data, 'city', '');
        this.fields.lat = String(_.get(data, 'lat', 0));
        this.fields.lng = String(_.get(data, 'lng', 0));
      },

      async getClients() {
        const { data } = await this.$http.get('clients', { params: { noPagination: true } });
        this.clients = _.get(data, 'data', []);
        this.$forceUpdate();
      },

      openLogoFileBrowser() {
        this.$refs.logo.click();
      },

      selectLogoFile() {
        const fileData = this.$refs.logo.files[0];
        if (fileData) {
          this.fields.logo = fileData;
          this.assignment.logo_src = URL.createObjectURL(fileData);
          this.$forceUpdate();
        }
      },

      removeLogoFile() {
        this.fields.logo = null;
        this.$refs.logo.value = '';
        this.$forceUpdate();
      },

      openBriefingFileBrowser() {
        this.$refs.briefing.click();
      },

      selectBriefingFile() {
        const fileData = this.$refs.briefing.files[0];
        if (fileData) {
          this.fields.briefing = fileData;
          this.$forceUpdate();
        }
      },

      removeBriefingFile() {
        this.fields.briefing = null;
        this.$refs.briefing.value = '';
        this.$forceUpdate();
      },

      async deleteAssignment() {
        // eslint-disable-next-line no-restricted-globals,no-alert
        if (confirm('Weet je het zeker?')) {
          try {
            await api.deleteAssignment(this.assignmentId);

            this.getAssignments();
            this.$toast.success('Opdracht is verwijderd', 'Ok');
            await this.$router.push({ name: 'assignments.index' });
          } catch {
            // catch error
          }
        }
      },
    },
  };
</script>
