<template>
  <div>
    <FullCalendar
      :events="calendarOptions.events"
      :options="calendarOptions"
      style="width: 70%; margin: auto;" />
    <div v-if="showTooltip" class="event-tooltip" :style="{ top: `${tooltipY}px`, left: `${tooltipX}px` }">
      <h3>{{ tooltipEvent.title }}</h3>
      <p>{{ tooltipEvent.extendedProps.description }}</p>
    </div>
  </div>
</template>


<script>

  import FullCalendar from '@fullcalendar/vue';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import api from '../api';

  export default {
    components: {
      FullCalendar,
    },
    data() {
      return {
        showTooltip: false,
        tooltipX: 0,
        tooltipY: 0,
        tooltipEvent: null,
        calendarOptions: {
          plugins: [dayGridPlugin],
          initialView: 'dayGridMonth',
          // you can add more options here, see: https://fullcalendar.io/docs
          events: [],
          eventClick: info => {
            window.open(info.event.url, '_blank');
            info.jsEvent.preventDefault();
          },
          eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          },
          eventMouseEnter: this.showEventDetails,
          eventMouseLeave: this.hideEventDetails,
        },
      };
    },
    created() {
      this.getAssignments();
    },
    methods: {
      async getAssignments() {
        const { data } = await api.getCalendarAssignments();
        this.calendarOptions.events = data;
      },
      showEventDetails(info) {
        this.tooltipX = info.jsEvent.pageX + 10;
        this.tooltipY = info.jsEvent.pageY + 10;
        this.tooltipEvent = info.event;
        this.showTooltip = true;
      },
      hideEventDetails() {
        this.showTooltip = false;
      },
    },
  };
</script>
