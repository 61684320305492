import http from '../../http';

export default {
  createUser(params) {
    return http.post('users', params);
  },

  inviteUser(userId) {
    return http.post(`users/invite/${userId}`);
  },

  getUsers(params) {
    return http.get('users', params);
  },

  getUserById(id) {
    return http.get(`users/${id}`);
  },

  updateUserById(userId, params) {
    return http.post(`users/${userId}`, params);
  },

  deleteUser(userId) {
    return http.delete(`users/${userId}`);
  },

  getClients(params) {
    return http.get('clients', params);
  },

  getAvailableRoles(params) {
    return http.get('users/getAvailableRoles', params);
  },

  updatePassword(params) {
    return http.post('users/change-password', params);
  },

  getSecurityPasses(id, params) {
    return http.get(`securitypasses/${id}`, params);
  },

  updateSecurityPasses(id, params) {
    return http.post(`securitypasses/${id}`, params);
  },
  import(params) {
    return http.post('users/import', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
