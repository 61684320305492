export default {
  methods: {
    hasDriverLicense(value) {
      return this.user.securityprofile.has_drivers_license === value;
    },
    hasCar(value) {
      return this.user.securityprofile.has_car === value;
    },
    hasSecurityType(value) {
      return this.user.securityprofile.securitytype === value;
    },
    hasPostPreferences(id) {
      const postPreferences = this.user.securityprofile.postpreferences || [];
      const result = postPreferences.find(postPreference => postPreference.id === id);
      return !!(result && Object.keys(result).length > 0);
    },
  },
};
