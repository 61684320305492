<template>
  <div class="as-header">
    <div class="as-header__image">
      <img
        :src="logo"
        alt="logo">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div
            class="d-flex align-items-end align-items-sm-center as-header__holder">
            <h1 class="mb-0 mr-4 is-white flex-center">
              <span class="mr-4 mb-1 mb-sm-0">{{ title }}</span>

              <span class="city-banner flex-center">
                <img
                  src="/img/map-marker-blue.svg"
                  alt="marker"
                  class="ac-icon mr-2">
                {{ city }}
              </span>
            </h1>
            <router-link
              v-if="canUpdate"
              :to="{ name: 'assignments.edit', params: { assignmentId: id } }"
              class="btn btn--secondary ml-auto">
              Opdracht bewerken
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: [String, Number],
        default: null,
      },
      title: {
        type: String,
        required: true,
      },
      city: {
        type: String,
        default: null,
      },
      logo: {
        type: String,
        required: true,
      },
      canUpdate: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
