import View from '../View.vue';

import Index from '../pages/Index.vue';

export default [
  {
    path: '/',
    component: View,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Index,
      },
    ],
  },
];
