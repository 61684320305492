<template>
  <div>
    <div
      class="row">
      <div class="col-md-12">
        <div class="row">
          <template
            v-if="
              registrations &&
                registrations.data &&
                Object.keys(registrations.data).length > 0">
            <div
              v-for="registration in registrations.data"
              :key="registration.id"
              class="col-md-6 col-lg-3 mb-3">
              <app-assignment-shift-registration :assignment-registration="registration" />
            </div>

            <div class="col-md-12">
              <pagination
                class="page-indicator"
                :data="registrations"
                :show-disabled="true"
                :limit="2"
                @pagination-change-page="changePage" />
            </div>
          </template>
          <template v-else>
            <div class="col-md-12">
              <div class="alert alert-info">
                Geen aanmeldingen gevonden.
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppAssignmentShiftRegistration from '@components/AppAssignmentShiftRegistration.vue';
  import pagination from 'laravel-vue-pagination';
  import api from '../api';

  export default {
    components: { AppAssignmentShiftRegistration, pagination },
    props: {
      finished: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        registrations: {},
        page: 1,
      };
    },

    created() {
      this.getRegistrations();
    },

    methods: {
      async getRegistrations() {
        const payload = {
          page: this.page,
        };

        if (this.finished) {
          payload.finished = true;
        }

        const { data } = await api.getRegistrations(payload);
        this.registrations = data;
      },

      changePage(page) {
        this.page = page;
        this.getRegistrations();
      },
    },
  };
</script>
