<template>
  <div class="custom-control custom-radio custom-control-inline">
    <input
      :id="id"
      type="radio"
      :name="name"
      class="custom-control-input"
      :value="initialValue"
      :disabled="disabled"
      :checked="checked"
      @change="$emit('input', $event.target.value)">
    <label
      class="custom-control-label"
      :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        default: null,
      },
      initialValue: {
        type: [String, Number, Boolean],
        default: null,
      },
      checked: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
