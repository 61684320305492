<template>
  <div
    v-if="assignment && loaded">
    <app-assignment-header
      :id="assignment.id"
      :logo="assignment.logo_src"
      :city="assignment.city"
      :title="assignment.title"
      :can-update="assignment.can_update" />

    <template v-if="assignment.can_update">
      <assignment-navigation
        :assignment="assignment"
        :active-tab="currentTab"
        @ontabselect="changeTab" />
    </template>

    <template v-if="$root.$data.user.is_security">
      <assignment-security-navigation assignment="assignment" active-tab="currentTab" @ontabselect="changeTab" />
    </template>

    <template v-if="currentTab === 'show'">
      <app-tab-show
        :assignment="assignment"
        @updateassignment="getAssignment" />
    </template>

    <template v-if="currentTab === 'registrations'">
      <app-tab-registrations
        :assignment="assignment"
        @updateassignment="getAssignment" />
    </template>

    <template v-if="currentTab === 'hours'">
      <app-tab-hours
        :assignment="assignment"
        @updateassignment="getAssignment" />
    </template>
    <template v-if="currentTab === 'reports'">
      <app-tab-reports
        :assignment="assignment"
        @updateassignment="getAssignment" />
    </template>
    <template v-if="currentTab === 'qrcode'">
      <app-tab-qrcode
        :assignment="assignment" />
    </template>
    <template v-if="currentTab === 'checkins'">
      <app-tab-checkins
        :assignment="assignment" />
    </template>
    <template v-if="currentTab === 'files'">
      <app-tab-files
        :assignment="assignment"
        :assignmentshifts="userAssignmentShifts"
        @updateassignment="getAssignment" />
    </template>
  </div>
</template>

<script>
  import avatarPlaceholder from '../../../helpers';
  import api from '../api';
  import AssignmentNavigation from '../components/AssignmentNavigation.vue';
  import AppTabShow from '../components/tabs/AppTabShow.vue';
  import AppTabRegistrations from '../components/tabs/AppTabRegistrations.vue';
  import AppTabHours from '../components/tabs/AppTabHours.vue';
  import AppTabReports from '../components/tabs/AppTabReports.vue';
  import AppAssignmentHeader from '../components/AppAssignmentHeader.vue';
  import AppTabQrcode from "@modules/Assignments/components/tabs/AppTabQrcode.vue";
  import AppTabCheckins from "@modules/Assignments/components/tabs/AppTabCheckins.vue";
  import AssignmentSecurityNavigation from "@modules/Assignments/components/AssignmentSecurityNavigation.vue";
  import AppTabFiles from "@modules/Assignments/components/tabs/AppTabFiles.vue";

  export default {
    components: {
      AssignmentSecurityNavigation,
      AppTabQrcode,
      AppAssignmentHeader,
      AppTabRegistrations,
      AppTabReports,
      AppTabHours,
      AppTabShow,
      AssignmentNavigation,
      AppTabCheckins,
      AppTabFiles,
    },
    data() {
      return {
        assignment: {},
        currentTab: 'show',
        loaded: false,
        userAssignmentShifts: [],
      };
    },

    async created() {
      if (this.$route.params.tab) {
        this.currentTab = this.$route.params.tab;
      }

      await this.getAssignment();
      await this.getUserAssignmentShifts();
    },

    methods: {
      async getAssignment() {
        const assignmentId = this.$route.params.assignmentId;
        const { data } = await api.getAssignmentById(assignmentId);
        this.assignment = data.data;

        if (!this.assignment.logo_src) {
          this.assignment.logo_src = avatarPlaceholder;
        }

        this.loaded = true;
      },
      async getUserAssignmentShifts() {
        const assignmentId = this.$route.params.assignmentId;
        if (this.$root.$data.user.is_security) {
          const { data } = await api.getUserAssignmentShifts(assignmentId);
          this.userAssignmentShifts = data;
        } else {
          this.userAssignmentShifts = this.assignment.assignmentshifts;
        }
      },

      changeTab(tab) {
        this.currentTab = tab;
        this.$router.push({ name: 'assignments.show', params: { tab } });

        this.getAssignment();
      },
    },
  };
</script>
