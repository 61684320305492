import http from '../../http';

export default {
  getReviews(params) {
    return http.get('reviews', { params });
  },

  getAvailableAssignments(params) {
    return http.get('reviews/get-available-assignments', { params });
  },

  createReview(payload) {
    return http.post('reviews', payload);
  },
  deleteReview(id) {
    return http.delete(`reviews/${id}`);
  },
};
