import { routes as Dashboard } from './Dashboard';
import { routes as Client } from './Clients';
import { routes as Users } from './Users';
import { routes as Assignments } from './Assignments';
import { routes as Categories } from './Categories';
import { routes as Calendar } from './Calendar';

export default [
  ...Dashboard,
  ...Client,
  ...Users,
  ...Assignments,
  ...Categories,
  ...Calendar,
];
