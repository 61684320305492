<template>
  <div class="container mt-5">
    <div class="col">
      <div v-for="checkin in checkinData">
        <div class="row justify-content-between">
          <h2 class="mt-5">{{checkin.assignment_shift_name}}</h2>
          <button class="btn btn-primary mt-5 ml-3" @click="getReport(checkin.assignment_shift_id)">Exporteer shows en now-shows</button>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Naam</th>
              <th scope="col">Checkin tijd</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="checkinItem in checkin.checkins">
              <td>{{checkinItem.username}}</td>
              <td>{{checkinItem.date_time_formatted}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../api';

  export default {
    props: {
      assignment: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        checkinData: [],
      };
    },

    async created() {
      const response = await api.getCheckins(this.assignment.id);
      const data = response.data;
      this.checkinData = data.checkins;
      console.log(this.checkinData);
    },

    methods: {
      async getReport(shiftId) {
        const response = await api.exportShiftCheckins(shiftId);
        // download the excel created using Maatwebsite/excel
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'checkins.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
    },
  };
</script>
