<template>
  <div class="custom-control custom-checkbox">
    <input
      :id="id"
      type="checkbox"
      :name="name"
      class="custom-control-input"
      :value="initialValue"
      :initial-value="initialValue"
      :checked="checked"
      :disabled="disabled"
      @change="onCheckedChange">
    <label
      class="custom-control-label"
      :for="id">
      <slot></slot>
    </label>
  </div>
</template>


<script>
  export default {
    props: {
      checked: {
        type: [Boolean, Number],
        default: false,
      },
      id: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        default: null,
      },
      value: {
        type: [String, Number],
        default: null,
      },
      initialValue: {
        type: [String, Number],
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onCheckedChange(event) {
        if (event.target.checked) {
          this.$emit('input', Number(event.target.value));
        } else {
          this.$emit('input', 0);
        }
      },
    },
  };
</script>
