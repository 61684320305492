<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>
          Gebruiker wijzigen
        </h2>
        <app-form
          :submit-form="updateClient"
          :errors="errors"
          :client="client" />
      </div>
    </div>
  </div>
</template>

<script>
  import AppForm from '../components/AppForm.vue';
  import avatarPlaceholder from '../../../helpers';
  import api from '../api';

  export default {
    components: {
      AppForm,
    },
    data() {
      return {
        client: {},
        errors: {},
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        const clientId = this.$route.params.clientId;
        const { data } = await api.getClientById(clientId);
        this.client = data.data;

        if (!this.client.logo_src) {
          this.client.logo_src = avatarPlaceholder;
        }
      },
      async updateClient(event) {
        const clientId = this.$route.params.clientId;
        const form = event.target;
        const formData = new FormData(form);

        for (const [key, value] of formData.entries()) {
          if (key !== 'logo') {
            this.client[key] = value;
          }
        }

        // File will be added separately to prevent insert [object file] inside the client object
        const file = form.querySelector('input[type="file"').files[0];
        if (file) {
          this.client.logo_src = URL.createObjectURL(file);
        }

        formData.append('_method', 'put');

        try {
          await api.updateClientById(clientId, formData);
          this.errors = {};
          this.$toast.success('Gebruiker is gewijzigd', 'Ok');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = error.response.data.errors;
        }
      },
    },
  };
</script>
