<template>
  <div class="row">
    <div
      v-for="registration in registrations"
      :key="registration.id"
      class="col-md-12 mt-3">
      <div class="hour-validation-card">
        <div class="d-flex h-100">
          <div class="hour-validation-card__avatar">
            <img
              :src="registration.user.avatar_src"
              alt="avatar">
          </div>

          <div class="hour-validation-card__person-info ml-3">
            <h3>{{ registration.user.full_name }}</h3>
            <div class="flex-center">
              <i
                v-if="registration.securitytype === 1"
                class="material-icons ac-icon">
                remove_red_eye
              </i>
              <img
                v-if="registration.securitytype === 2"
                src="/img/ic-security.svg"
                alt="icon"
                class="ac-icon">
              <p class="ml-1">
                {{ registration.readable_securitytype }}
              </p>
            </div>
            <router-link
              :to="{ name: 'users.show', params: { userId: registration.user.id }}"
              class="btn btn--secondary btn--small">
              Review geven
            </router-link>
          </div>
        </div>

        <div class="hour-validation-card__hours mt-2 mt-sm-0 ml-sm-auto">
          <div class="hour-validation-card__time">
            <p>Van</p>
            <span>{{ registration.start_time }}</span>
          </div>
          <div class="hour-validation-card__time">
            <p>Tot</p>
            <span>{{ registration.end_time }}</span>
          </div>
          <div class="hour-validation-card__time">
            <p>totaal</p>
            <span>{{ registration.total_hours }}</span>
          </div>
        </div>

        <div class="d-flex ml-sm-5">
          <div class="flex-center mt-2">
            <a
              class="assignment-card__box btn-box success"
              :class="{
                'accepted' : registration.status === 7,
                'no-events' : registration.status > 5
              }"
              @click.prevent="approveHours(registration.id)">
              <i class="material-icons ac-icon">
                done
              </i>
            </a>
            <a
              class="assignment-card__box btn-box danger"
              :class="{
                'declined' : registration.status === 6,
                'no-events' : registration.status > 5
              }"
              @click.prevent="declineHours(registration.id)">
              <i class="material-icons ac-icon">
                close
              </i>
            </a>
          </div>
        </div>

        <div class="d-flex flex-column ml-sm-3">
          <router-link
            v-if="registration.can_update"
            tag="a"
            target="_blank"
            :to="{
              name: 'assignments.registration.edit',
              params: { registrationId: registration.id }
            }"
            class="btn btn--secondary mt-3 mt-sm-0 mb-3">
            Bewerken
          </router-link>
          <a
            v-if="registration.briefing_src !== ''"
            :href="registration.briefing_src"
            target="_blank"
            class="btn btn--secondary">
            Werkbrief downloaden
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      registrations: {
        type: [Object, Array],
        required: true,
      },
      approveHours: {
        type: Function,
        required: true,
      },
      declineHours: {
        type: Function,
        required: true,
      },
    },
  };
</script>
