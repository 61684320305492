var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assignment-card"},[_c('h3',[_vm._v(_vm._s(_vm.assignmentRegistration.assignmentshift.assignment.title))]),_vm._v(" "),_c('div',{staticClass:"assignment-card__background"},[_c('img',{staticClass:"assignment-card__image",attrs:{"src":_vm.assignmentRegistration.assignmentshift.assignment.logo_src,"alt":_vm.assignmentRegistration.assignmentshift.assignment.title}}),_vm._v(" "),_c('div',{staticClass:"assignment-card__overlay-text"},[_c('img',{staticClass:"ac-icon",attrs:{"src":"/img/map-marker.svg","alt":"marker"}}),_vm._v(" "),_c('p',{staticClass:"ml-2 is-white"},[_vm._v("\n        "+_vm._s(_vm.assignmentRegistration.assignmentshift.assignment.city)+"\n      ")])])]),_vm._v(" "),_c('p',{staticClass:"mt-3"},[_c('label',[_vm._v("Shift:")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.assignmentRegistration.readable_status))])]),_vm._v(" "),_c('div',{staticClass:"assignment-card__time mt-0"},[_c('i',{staticClass:"material-icons ac-icon"},[_vm._v("\n      event\n    ")]),_vm._v(" "),_c('p',{staticClass:"ml-2"},[_vm._v("\n      "+_vm._s(_vm.assignmentRegistration.assignmentshift.date_formatted)+"\n    ")])]),_vm._v(" "),_c('app-registered-boxes',{attrs:{"current-supervisors":_vm.assignmentRegistration.assignmentshift.current_supervisors,"max-supervisors":_vm.assignmentRegistration.assignmentshift.max_supervisors,"current-security-guards":_vm.assignmentRegistration.assignmentshift.current_securityguards,"max-security-guards":_vm.assignmentRegistration.assignmentshift.max_securityguards}}),_vm._v(" "),(_vm.assignmentRegistration.assignmentshift.can_register_hours)?_c('router-link',{staticClass:"btn btn--secondary",attrs:{"to":{ name: 'assignments.registration',
           params: {
             assignmentId: _vm.assignmentRegistration.assignmentshift.assignment_id,
             assignmentshiftId: _vm.assignmentRegistration.assignmentshift_id
           }
    }}},[_vm._v("\n    Uren doorgeven\n  ")]):_vm._e(),_vm._v(" "),_c('router-link',{class:[
      !_vm.assignmentRegistration.assignmentshift.can_register_hours ?
        'btn btn--secondary' :
        'ml-2 is-gray'
    ],attrs:{"tag":"a","to":{
      name: 'assignments.show',
      params: {
        assignmentId: _vm.assignmentRegistration.assignmentshift.assignment_id
      }
    }}},[_vm._v("\n    Meer info\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }