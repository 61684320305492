<template>
  <div>
    <div
      v-if="assignments && Object.keys(assignments).length > 0"
      class="row mb-10">
      <div class="col-md-12">
        <h2>Mijn opdrachten:</h2>
        <div class="row">
          <div
            v-for="assignment in assignments"
            :key="assignment.id"
            class="col-md-6 col-lg-3 mb-3">
            <app-assignment
              :assignment="assignment" />
          </div>
        </div>

        <router-link
          v-if="$root.$data.user.can_create_assignment"
          :to="{ name: 'assignments.create' }"
          class="btn ml-auto">
          Opdracht plaatsen
        </router-link>

        <router-link
          v-if="$root.$data.user.can_create_assignment"
          :to="{ name: 'assignments.index' }"
          class="ml-2 is-default">
          Mijn opdrachten
        </router-link>
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-md-12">
        <h2>Vandaag:</h2>

        <div class="row">
          <div
            v-for="assignment in assignments"
            :key="assignment.id"
            class="col-md-6 col-lg-3 mb-3">
            <app-today-info :assignment="assignment" />
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-10">
      <div class="col-md-12">
        <h2>Uren doorgegeven:</h2>

        <div class="row">
          <div
            v-for="assignment in finishedAssignments"
            :key="assignment.id"
            class="col-md-6 col-lg-3 mb-3">
            <app-registered-hours-info :assignment="assignment" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppAssignment from '@components/AppAssignment.vue';
  import api from '../api';
  import AppTodayInfo from './AppTodayInfo.vue';
  import AppRegisteredHoursInfo from './AppRegisteredHoursInfo.vue';

  export default {
    components: {
      AppTodayInfo,
      AppRegisteredHoursInfo,
      AppAssignment,
    },

    data() {
      return {
        assignments: [],
        finishedAssignments: [],
      };
    },

    created() {
      this.getAssignments();
      this.getFinishedAssignments();
    },

    methods: {
      async getAssignments() {
        const { data } = await api.getAssignments();
        this.assignments = data.data;
      },

      async getFinishedAssignments() {
        const { data } = await api.getAssignments({
          type: 'finished',
        });
        this.finishedAssignments = data.data;
      },
    },
  };
</script>
