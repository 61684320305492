<template>
  <div
    v-if="showModal"
    class="modal-container">
    <form
      enctype="multipart/form-data"
      novalidate
      class="modal-content"
      @submit.prevent="startImport">
      <button
        class="close-button"
        @click.prevent="$emit('close')">
        X
      </button>
      <h1>Gebruikers importeren</h1>
      <div class="dropbox">
        <input
          type="file"
          :name="'file'"
          accept="text/csv"
          class="input-file"
          multiple
          @change="fileChange($event.target.name, $event.target.files);">
      </div>

      <button
        type="submit"
        class="btn btn-primary">
        Importeren
      </button>
    </form>
  </div>
</template>


<script>

  import mixins from '../mixins';
  import api from '../api';

  export default {
    components: {
    },
    mixins: [mixins],
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        formData: {},
        uploadFile: 'uploadFile',
      };
    },
    methods: {
      async startImport() {
        await api.import(this.formData);
        this.$emit('closeAndRefresh');
      },
      fileChange(fieldName, fileList) {
        this.formData = new FormData();

        if (!fileList.length) return;

        Array.from(Array(fileList.length).keys()).map(x => {
          return this.formData.append(fieldName, fileList[x], fileList[x].name);
        });

      },
    },
  };
</script>
