import View from '../View.vue';

/* eslint-disable */
import { Create, Edit, Show, Index } from '../pages/*';
/* eslint-enable */

export default [
  {
    path: '/users',
    component: View,
    children: [
      {
        path: '',
        name: 'users.index',
        component: Index,
      },
      {
        path: 'create',
        name: 'users.create',
        component: Create,
      },
      {
        path: ':userId/edit',
        name: 'users.edit',
        component: Edit,
      },
      {
        path: ':userId',
        name: 'users.show',
        component: Show,
      },
    ],
  },
];
