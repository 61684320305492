<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="row">
        <div class="col-md-3">
          <div class="list-group">
            <a
              v-for="shift in assignment.assignmentshifts"
              :key="shift.id"
              class="list-group-item list-group-item-action"
              :class="{ 'active': currentShift && currentShift.id === shift.id }"
              href="#"
              @click.prevent="setCurrentShift(shift)">
              <div>
                <p class="is-bold">{{ shift.date_formatted }}</p>
                <div class="flex-center mt-2 mb-3">
                  <i class="material-icons ac-icon big mr-2">
                    access_time
                  </i>
                  <p>{{ shift.time_formatted }}</p>
                </div>

                <div class="flex-center mt-2 mb-3">
                  <div class="assignment-card__box">
                    <i class="material-icons ac-icon">
                      remove_red_eye
                    </i>
                    <p class="ml-1">
                      {{ shift.current_supervisors }}/{{ shift.max_supervisors }}
                    </p>
                  </div>
                  <div class="assignment-card__box">
                    <img
                      src="/img/ic-security.svg"
                      alt="icon"
                      class="ac-icon">
                    <p class="ml-1">
                      {{ shift.current_securityguards }}/{{ shift.max_securityguards }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <form @submit.prevent="getRegistrations">
                <div><strong>Filteren:</strong></div>

                <div class="form-group">
                  <label>Status:</label>

                  <div class="custom-control custom-checkbox">
                    <input
                      id="state-pending"
                      v-model="query.state"
                      type="checkbox"
                      value="5"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="state-pending">
                      Uren valideren
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      id="state-accepted"
                      v-model="query.state"
                      type="checkbox"
                      value="7"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="state-accepted">
                      Uren goedgekeurd
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      id="state-denied"
                      v-model="query.state"
                      value="6"
                      type="checkbox"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="state-denied">
                      Uren afgekeurd
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="query-search">Naam:</label>
                  <input
                    id="query-search"
                    v-model="query.search"
                    type="text"
                    placeholder="Zoek op naam"
                    class="form-control secondary">
                </div>

                <button
                  type="submit"
                  class="btn">
                  Filters toepassen
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div
            v-if="!registrations ||
              !registrations.data || Object.keys(registrations.data).length < 1"
            class="alert alert-info">
            Geen resultaten
          </div>

          <div
            v-if="registrations &&
              registrations.data && Object.keys(registrations.data).length > 0">
            <div class="row mb-2">
              <div class="col-md-12">
                <div class="text-right d-flex d-sm-block">
                  <a
                    v-if="currentShift"
                    :href="'/assignmentshift/export-hours/' + currentShift.id"
                    class="btn btn--secondary">
                    Exporteren
                  </a>
                  &nbsp;
                  <a
                    href="#"
                    class="btn btn--secondary"
                    @click.prevent="approveAll">
                    Alles goedkeuren
                  </a>
                  &nbsp;
                  <a
                    href="#"
                    class="btn btn--secondary"
                    @click.prevent="denyAll">
                    Alles afkeuren
                  </a>
                </div>
              </div>
            </div>

            <app-hour-validation-card
              :registrations="registrations.data"
              :approve-hours="approve"
              :decline-hours="deny" />
            <pagination
              class="page-indicator"
              :data="registrations"
              :show-disabled="true"
              :limit="2"
              @pagination-change-page="changePage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pagination from 'laravel-vue-pagination';
  import api from '../../api';
  import AppHourValidationCard from '../AppHourValidationCard.vue';

  export default {
    components: {
      pagination,
      AppHourValidationCard,
    },

    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        currentShift: {},
        registrations: [],
        query: {
          sort: '',
          page: 1,
          search: '',
          state: [
            5,
            6,
            7,
          ],
          securitytypes: [
            1,
            2,
          ],
        },
      };
    },

    created() {
      this.initSetCurrentShift();
    },

    methods: {
      changePage(page) {
        this.query.page = page;
        this.getRegistrations();
      },

      initSetCurrentShift() {
        if (this.currentShift && Object.keys(this.currentShift).length > 0) {
          this.setCurrentShift(_.find(this.assignment.assignmentshifts, {
            id: this.currentShift.id,
          }));
        } else {
          this.setCurrentShift(_.head(this.assignment.assignmentshifts) || {});
        }
      },

      async getRegistrations() {
        if (this.currentShift && this.currentShift.id) {
          const { data } = await api.getRegistrationsByShift(this.currentShift.id, this.query);
          this.registrations = data;
        }
      },

      async setCurrentShift(shift) {
        this.currentShift = shift;
        this.getRegistrations();
      },

      async updateRegistrationStatus(assignmentregistrationId, status) {
        if (this.currentShift && this.currentShift.id) {
          await api.updateRegistrationStatus(assignmentregistrationId,
                                             this.currentShift.id, status);
          this.getRegistrations();
          this.$emit('updateassignment');
        }
      },

      async updateRegistrationStatuses(status) {
        if (this.currentShift && this.currentShift.id) {
          await api.updateRegistrationStatuses(this.currentShift.id,
                                               this.query,
                                               status);
          this.getRegistrations();
          this.$emit('updateassignment');
        }
      },

      async approve(assignmentregistrationId) {
        this.updateRegistrationStatus(assignmentregistrationId, 7);
      },

      async deny(assignmentregistrationId) {
        this.updateRegistrationStatus(assignmentregistrationId, 6);
      },

      async approveAll() {
        this.updateRegistrationStatuses(7);
      },

      async denyAll() {
        this.updateRegistrationStatuses(6);
      },
    },
  };
</script>
