<template>
  <div class="form-group">
    <label :for="id">
      <slot></slot>
    </label>
    <select
      :id="id"
      class="browser-default custom-select"
      :name="name"
      @change="$emit('input', $event.target.value)">
      <option
        v-for="(item, index) in data"
        :key="index"
        :value="item"
        :selected="item === selected">
        {{ item }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        required: true,
      },
      selected: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        required: true,
      },
      data: {
        type: [Object, Array],
        required: true,
      },
    },
  };
</script>
