<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Categorieën</h2>
        <div class="card">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Naam</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="category in categories.data"
                :key="category.id"
                class="cursor-normal-except-first">
                <router-link
                  tag="td"
                  :to="{ name: 'categories.edit', params: { categoryId: category.id } }">
                  {{ category.name }}
                </router-link>
                <td class="d-flex align-items-center align-icons-rights">
                  <a
                    v-if="category.can_delete"
                    class="ml-2"
                    title="Verwijderen"
                    @click.prevent="deleteCategory(category.id)">
                    <i class="material-icons">
                      delete_outline
                    </i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <pagination
            class="page-indicator"
            :data="categories"
            :show-disabled="true"
            :limit="2"
            @pagination-change-page="changePage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pagination from 'laravel-vue-pagination';
  import api from '../api';

  export default {
    components: {
      pagination,
    },
    data() {
      return {
        categories: {},
        query: {
          page: 1,
        },
      };
    },
    created() {
      this.getCategories();
    },
    methods: {
      async getCategories() {
        const { data } = await api.getCategories({ params: this.query });
        console.log(data);
        this.categories = data;
      },
      changePage(page) {
        this.query.page = page;
        this.getCategories();
      },
      async deleteCategory(id) {
        if (confirm('Weet je zeker dat je deze categorie wilt verwijderen?')) {
          await api.deleteCategory(id);
          await this.getCategories();
          this.$toast.success('Categorie verwijderd');
        }
      },
    },
  };
</script>
