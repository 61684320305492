<template>
  <div v-if="this.$root.$data.user">
    <top-navigation />

    <div id="page">
      <router-view />
    </div>

    <default-footer />
  </div>
</template>

<script>
  import TopNavigation from './modules/Layout/Nav.vue';
  import DefaultFooter from './modules/Layout/Footer.vue';

  export default {
    components: {
      TopNavigation,
      DefaultFooter,
    },
    data() {
      return {
        app_name: window.app_name,
        app_theme: window.app_theme,
      };
    },
    computed: {
      logo() {
        let logo = '';
        switch (this.app_theme) {
          case 'protegos':
            logo = '/img/logo-protegos.png';
            break;
          default:
            logo = '/img/logo-white.svg';
        }
        return logo;
      },
    },
    created() {
      this.getCurrentUser();
    },

    methods: {
      async getCurrentUser() {
        const { data } = await this.$http.get('users/getCurrentUser');
        this.$root.user = data.data;
      },
    },
  };
</script>
