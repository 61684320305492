import http from '../../http';

export default {
  getCategories(params) {
    return http.get('/categories', params);
  },

  getCategoryById(id) {
    return http.get(`/categories/${id}`);
  },

  updateCategory(id, params) {
    return http.post(`/categories/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  createCategory(params) {
    return http.post('categories', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteCategory(id) {
    return http.delete(`/categories/${id}`);
  },
};
