<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div
          v-if="!assignment || !assignmentshift || !assignmentshift.can_register_hours"
          class="alert alert-info">
          Je kunt geen uren doorgeven voor deze shift.
        </div>

        <template v-if="assignment && assignmentshift && assignmentshift.can_register_hours">
          <div class="mb-5">
            <h1 class="mb-3">
              Gewerkte uren doorgeven
            </h1>
            <app-assignment-with-shift
              :assignment="assignment"
              :assignmentshift="assignmentshift" />
          </div>

          <form @submit.prevent="submitForm">
            <div class="flex-half">
              <div
                :class="{ 'has-errors': errors.start_datetime }"
                class="form-group">
                <label for="start_date">Start datum:</label>
                <input
                  id="start_date"
                  v-model="fields.start_date"
                  type="date"
                  class="form-control"
                  @change="onDateChange">
              </div>
              <div
                :class="{ 'has-errors': errors.end_datetime }"
                class="form-group">
                <label for="end_date">Eind datum:</label>
                <input
                  id="end_date"
                  v-model="fields.end_date"
                  type="date"
                  class="form-control"
                  @change="onDateChange">
              </div>
            </div>

            <div class="flex-half">
              <div
                :class="{ 'has-errors': errors.start_datetime }"
                class="form-group">
                <label for="start_time">Start tijd:</label>
                <input
                  id="start_time"
                  v-model="fields.start_time"
                  type="time"
                  class="form-control"
                  @change="onDateChange">
              </div>
              <div
                :class="{ 'has-errors': errors.end_datetime }"
                class="form-group">
                <label for="end_time">Eind tijd:</label>
                <input
                  id="end_time"
                  v-model="fields.end_time"
                  type="time"
                  class="form-control"
                  @change="onDateChange">
              </div>
            </div>

            <div class="form-group">
              <label for="total_hours">Totaal:</label>
              <input
                id="total_hours"
                v-model="fields.total_hours"
                :class="{ 'has-errors': errors.total_hours }"
                type="text"
                disabled
                class="form-control">
            </div>

            <div
              :class="{ 'has-errors' : errors.briefing }"
              class="form-group">
              <label for="briefing">Werkbrief uploaden:</label>

              <div
                v-if="fields.briefing"
                class="alert alert-info">
                {{ fields.briefing.name }}

                <a
                  href="#"
                  @click.prevent="removeBriefingFile">Verwijder</a>
              </div>

              <div>
                <button
                  type="button"
                  class="btn btn--secondary"
                  @click.prevent="openBriefingFileBrowser">
                  Bestand selecteren
                </button>
                <input
                  v-show="false"
                  id="briefing"
                  ref="briefing"
                  type="file"
                  @change="selectBriefingFile('briefing')">
              </div>
            </div>

            <button
              type="submit"
              class="btn mt-5 mb-5"
              @click.prevent="submitForm">
              Opslaan
            </button>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import avatarPlaceholder from '../../../helpers';
  import api from '../api';
  import AppAssignmentWithShift from '../../../components/AppAssignmentWithShift.vue';

  export default {
    components: { AppAssignmentWithShift },
    data() {
      return {
        fields: {},
        assignment: {},
        assignmentshift: {},
        errors: {},
      };
    },
    created() {
      this.getAssignmentshift();
    },
    methods: {
      async getAssignmentshift() {
        const assignmentId = this.$route.params.assignmentId;
        const assignmentshiftId = this.$route.params.assignmentshiftId;

        const { data } = await api.getAssignmentshift(assignmentId, assignmentshiftId);
        this.assignment = _.get(data, 'assignment', {});
        this.assignmentshift = data;

        this.fields = {
          start_time: _.get(this.assignmentshift, 'start_time', ''),
          start_date: _.get(this.assignmentshift, 'start_date', ''),
          end_time: _.get(this.assignmentshift, 'end_time', ''),
          end_date: _.get(this.assignmentshift, 'end_date', ''),
          total_hours: _.get(this.assignmentshift, 'total_hours', ''),
        };

        if (!this.assignment.logo_src) {
          this.assignment.logo_src = avatarPlaceholder;
        }
      },

      onDateChange() {
        try {
          const startDateTime = moment(`${this.fields.start_date} ${this.fields.start_time}`, 'YYYY-MM-DD HH:mm');
          const endDateTime = moment(`${this.fields.end_date} ${this.fields.end_time}`, 'YYYY-MM-DD HH:mm');

          const duration = moment.duration(endDateTime.diff(startDateTime));
          let hours = duration.asHours();

          if (!hours || hours < 0) {
            hours = 0;
          }

          this.fields.start_datetime = startDateTime.format('YYYY-MM-DD HH:mm:ss');
          this.fields.end_datetime = endDateTime.format('YYYY-MM-DD HH:mm:ss');

          this.fields.total_hours = Math.round(hours * 10) / 10;
        } catch {
          // catch error
        }
      },

      async submitForm() {
        this.onDateChange();
        this.errors = {};

        try {
          const formData = new FormData();

          for (const key in this.fields) {
            if (this.fields[key]) {
              formData.append(key, this.fields[key]);
            }
          }

          await api.registerHoursByShift(this.assignmentshift.id, formData);

          this.$toast.success('Opgeslagen, uren zijn doorgegeven!', 'Ok');
          this.$router.push({
            name: 'assignments.show',
            params: { assignmentId: this.assignmentshift.assignment_id },
          });
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = _.get(error, 'response.data.errors', {});
        }
      },

      openBriefingFileBrowser() {
        this.$refs.briefing.click();
      },

      selectBriefingFile() {
        const fileData = this.$refs.briefing.files[0];
        if (fileData) {
          this.fields.briefing = fileData;
          this.$forceUpdate();
        }
      },

      removeBriefingFile() {
        this.fields.briefing = null;
        this.$refs.briefing.value = '';
        this.$forceUpdate();
      },
    },
  };
</script>
