<template>
  <div>
    <app-form />
  </div>
</template>

<script>
  import AppForm from '../components/AppForm.vue';

  export default {
    components: { AppForm },
  };
</script>
