<template>
  <div class="sub-nav">
    <div
      class="container">
      <div class="sub-nav__links list-unstyled p-0">
        <li
          class="sub-nav__list"
          :class="{ 'is-active' : activeTab === 'show'}">
          <a
            href="#"
            class="sub-nav__link"
            @click.prevent="onTabSelect('show')">
            Opdracht
          </a>
        </li>
        <li
          class="sub-nav__list"
          :class="{ 'is-active' : activeTab === 'files'}">
          <a
            href="#"
            class="sub-nav__link"
            @click.prevent="onTabSelect('files')">
            Bestanden uploaden
          </a>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      assignment: {
        type: Object,
        required: true,
      },
      activeTab: {
        type: String,
        default: null,
      },
    },

    methods: {
      onTabSelect(tab) {
        this.$emit('ontabselect', tab);
      },
    },
  };
</script>
