import View from '../View.vue';

/* eslint-disable */
import { Index } from '../pages/*';
/* eslint-enable */

export default [
  {
    path: '/calendar',
    component: View,
    children: [
      {
        path: '',
        name: 'calendar.index',
        component: Index,
      },
    ],
  },
];
