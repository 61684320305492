<template>
  <div class="flex-center mt-2 mb-3">
    <div class="assignment-card__box">
      <i class="material-icons ac-icon">remove_red_eye</i>
      <p class="ml-1">
        {{ currentSupervisors }}/{{ maxSupervisors }}
      </p>
    </div>
    <div class="assignment-card__box">
      <img
        src="/img/ic-security.svg"
        alt="security icon"
        class="ac-icon">
      <p class="ml-1">
        {{ currentSecurityGuards }}/{{ maxSecurityGuards }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      currentSupervisors: {
        type: [String, Number],
        default: null,
      },
      maxSupervisors: {
        type: [String, Number],
        default: null,
      },
      currentSecurityGuards: {
        type: [String, Number],
        default: null,
      },
      maxSecurityGuards: {
        type: [String, Number],
        default: null,
      },
    },
  };
</script>
