<template>
  <div>
    <form @submit.prevent="submitForm">
      <div
        class="form-group"
        :class="{ 'has-errors': errors.assignment_id }">
        <label>Selecteer evenement</label>
        <select
          v-model="fields.assignment_id"
          class="secondary browser-default custom-select"
          @change="getAvailableAssignments">
          <option
            disabled
            value="">
            Selecteer evenement
          </option>
          <option
            v-for="assignment in availableAssignments"
            :key="assignment.id"
            :value="assignment.id">
            {{ assignment.title }}
          </option>
        </select>
      </div>
      <div
        class="form-group"
        :class="{ 'has-errors': errors.assignmentshift_id }">
        <label>Selecteer shift</label>
        <select
          v-model="fields.assignmentshift_id"
          class="secondary browser-default custom-select">
          <option
            disabled
            value="">
            Selecteer shift
          </option>
          <option
            v-for="shift in availableAssignmentshifts"
            :key="shift.id"
            :value="shift.id">
            {{ shift.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>Selecteer rating</label>

        <div>
          <a
            v-for="i in 5"
            :key="i"
            @click.prevent="selectStar(i)">
            <i class="material-icons is-gold cursor-pointer">
              {{ (i > fields.stars) ? 'star_border' : 'star' }}
            </i>
          </a>
        </div>
      </div>

      <div class="form-group">
        <label>Toelichting</label>
        <textarea
          v-model="fields.comment"
          class="form-control secondary"></textarea>
      </div>

      <button
        class="btn"
        type="submit">
        Opslaan
      </button>
    </form>
  </div>
</template>

<script>
  import api from '../api';

  export default {
    props: {
      modelId: {
        type: Number,
        required: true,
      },

      modelType: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        fields: {},
        errors: {},

        availableAssignments: [],
        availableAssignmentshifts: [],
      };
    },

    created() {
      this.resetFields();
      this.getAvailableAssignments();
    },

    methods: {
      resetFields() {
        this.fields = {
          model_id: this.modelId,
          model_type: this.modelType,
          assignment_id: '',
          assignmentshift_id: '',
          stars: 1,
          comment: '',
        };
      },

      async submitForm() {
        try {
          await api.createReview(this.fields);
          this.errors = {};
          this.$emit('created');
          this.$toast.success('Review is geplaatst', 'Ok');
          this.resetFields();
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = _.get(error, 'response.data.errors', {});
        }
      },

      async getAvailableAssignments() {
        const params = {
          model_id: this.modelId,
          model_type: this.modelType,
          assignment_id: _.get(this.fields, 'assignment_id', null),
        };

        const { data } = await api.getAvailableAssignments(params);

        this.availableAssignments = _.get(data, 'assignments', []);
        this.availableAssignmentshifts = _.get(data, 'assignmentshifts', []);
      },

      selectStar(stars) {
        this.fields.stars = stars;
      },
    },
  };
</script>
