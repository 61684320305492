<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Categorie bewerken</h2>

        <app-form/>
      </div>
    </div>
  </div>
</template>

<script>
  import AppForm from '../components/AppForm.vue';
  import api from '../api';

  export default {
    components: {
      AppForm,
    },
    data() {
      return {
        category: {},
        loaded: false,
        errors: {},
        categoryId: null,
      };
    },
    created() {
      this.initialize();
    },
    methods: {
      async initialize() {
        this.categoryId = this.$route.params.categoryId;
      },
    },
  };
</script>
