<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <template v-if="registration && registration.can_update">
          <div class="mb-5">
            <h1 class="mb-3">
              Doorgegeven uren bewerken
            </h1>
          </div>

          <form @submit.prevent="submitForm">
            <div class="flex-half">
              <div
                :class="{ 'has-errors': errors.start_datetime }"
                class="form-group">
                <label for="start_date">Start datum:</label>
                <input
                  id="start_date"
                  v-model="fields.start_date"
                  type="date"
                  class="form-control"
                  @change="onDateChange">
              </div>
              <div
                :class="{ 'has-errors': errors.end_datetime }"
                class="form-group">
                <label for="end_date">Eind datum:</label>
                <input
                  id="end_date"
                  v-model="fields.end_date"
                  type="date"
                  class="form-control"
                  @change="onDateChange">
              </div>
            </div>

            <div class="flex-half">
              <div
                :class="{ 'has-errors': errors.start_datetime }"
                class="form-group">
                <label for="start_time">Start tijd:</label>
                <input
                  id="start_time"
                  v-model="fields.start_time"
                  type="time"
                  class="form-control"
                  @change="onDateChange">
              </div>
              <div
                :class="{ 'has-errors': errors.end_datetime }"
                class="form-group">
                <label for="end_time">Eind tijd:</label>
                <input
                  id="end_time"
                  v-model="fields.end_time"
                  type="time"
                  class="form-control"
                  @change="onDateChange">
              </div>
            </div>

            <button
              type="submit"
              class="btn mt-5 mb-5"
              @click.prevent="submitForm">
              Opslaan
            </button>
          </form>
        </template>
        <template v-if="!registration || !registration.can_update">
          <div class="alert alert-info">
            Niet mogelijk
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import api from '../api';

  export default {
    data() {
      return {
        registrationId: null,
        fields: {},
        registration: {},
        errors: {},
      };
    },

    created() {
      this.registrationId = this.$route.params.registrationId;
      this.getRegistration();
    },
    methods: {
      async getRegistration() {
        const { data: { data } } = await api.getRegistration(this.registrationId);
        this.registration = data;

        this.fields = {
          start_time: _.get(this.registration, 'start_time', ''),
          start_date: _.get(this.registration, 'start_date', ''),
          end_time: _.get(this.registration, 'end_time', ''),
          end_date: _.get(this.registration, 'end_date', ''),
        };
      },

      onDateChange() {
        try {
          const startDateTime = moment(`${this.fields.start_date} ${this.fields.start_time}`, 'YYYY-MM-DD HH:mm');
          const endDateTime = moment(`${this.fields.end_date} ${this.fields.end_time}`, 'YYYY-MM-DD HH:mm');
          this.fields.start_datetime = startDateTime.format('YYYY-MM-DD HH:mm:ss');
          this.fields.end_datetime = endDateTime.format('YYYY-MM-DD HH:mm:ss');
        } catch (err) {
          //
        }
      },

      async submitForm() {
        this.onDateChange();
        this.errors = {};

        try {
          const formData = new FormData();

          for (const key in this.fields) {
            if (this.fields[key]) {
              formData.append(key, this.fields[key]);
            }
          }

          await api.updateRegistration(this.registration.id, formData);
          this.getRegistration();

          this.$toast.success('Opgeslagen, de uren zijn gewijzigd!', 'Ok');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = _.get(error, 'response.data.errors', {});
        }
      },
    },
  };
</script>
