import View from '../View.vue';

/* eslint-disable */
import { Index, Create, Edit, Show } from '../pages/*';
/* eslint-enable */

export default [
  {
    path: '/clients',
    component: View,
    children: [
      {
        path: '',
        name: 'clients.index',
        component: Index,
      },
      {
        path: 'create',
        name: 'clients.create',
        component: Create,
      },
      {
        path: ':clientId/edit',
        name: 'clients.edit',
        component: Edit,
      },
      {
        path: ':clientId',
        name: 'clients.show',
        component: Show,
      },
    ],
  },
];
