import axios from 'axios';
import qs from 'qs';

const http = axios.create({
  baseURL: '/api/',
  paramsSerializer: params => qs.stringify(params, {
    skipNulls: true,
  }),
});

http.interceptors.response.use(undefined, error => {
  const res = error.response;
  console.log(res);

  //if (res.status === 401) {
   // window.location.reload();
  //}

  return Promise.reject(error);
});

export default http;
