<template>
  <div class="assignment-card">
    <h3>{{ assignment.title }}</h3>
    <div class="assignment-card__hours text-center">
      <span>{{ assignment.registrations_hours_confirmed }}</span>
      <span>/</span>
      <span>{{ assignment.total_registrations_hours_to_confirm }}</span>
    </div>
    <h3>personen hebben uren doorgegeven</h3>

    <router-link
      tag="a"
      :to="{ name: 'assignments.show', params: {
        assignmentId: assignment.id, tab: 'hours'
      } }"
      class="btn btn--secondary mt-3">
      Uren valideren
    </router-link>
  </div>
</template>

<script>
  export default {
    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },
  };
</script>
