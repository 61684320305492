<template>
  <div class="assignment-card">
    <h3>{{ assignment.title }}</h3>

    <div class="assignment-card__background">
      <img
        :src="assignment.logo_src"
        :alt="assignment.title"
        class="assignment-card__image">

      <div class="assignment-card__overlay-text">
        <img
          src="/img/map-marker.svg"
          alt="marker"
          class="ac-icon">
        <p class="ml-2 is-white">
          {{ assignment.city }}
        </p>
      </div>
    </div>

    <div class="assignment-card__time">
      <i class="material-icons ac-icon">
        access_time
      </i>
      <p class="ml-2">
        {{ assignment.date_formatted }}
      </p>
    </div>

    <div class="flex-center mt-2 mb-3">
      <div class="assignment-card__box">
        <i class="material-icons ac-icon">
          remove_red_eye
        </i>
        <p class="ml-1">
          {{ assignment.current_supervisors }}/{{ assignment.max_supervisors }}
        </p>
      </div>
      <div class="assignment-card__box">
        <img
          src="/img/ic-security.svg"
          alt="icon"
          class="ac-icon">
        <p class="ml-1">
          {{ assignment.current_securityguards }}/{{ assignment.max_securityguards }}
        </p>
      </div>
    </div>

    <div v-if="$root.$data.user.is_client_or_admin">
      <router-link
        tag="a"
        :to="{ name: 'assignments.show', params: {
          assignmentId: assignment.id, tab: 'registrations'
        } }"
        class="btn btn--secondary">
        Aanmeldingen
      </router-link>
      <router-link
        tag="a"
        :to="{ name: 'assignments.show', params: { assignmentId: assignment.id } }"
        class="ml-2 is-gray">
        Meer info
      </router-link>
    </div>
    <div v-if="$root.$data.user.is_security">
      <router-link
        tag="a"
        :to="{ name: 'assignments.show', params: { assignmentId: assignment.id } }"
        class="btn btn--secondary">
        Meer info
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },
  };
</script>
