<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-form/>
      </div>
    </div>
  </div>
</template>

<script>
  import AppForm from '../components/AppForm.vue';
  import api from '../api';

  export default {
    components: {
      AppForm,
    },
    data() {
      return {
        errors: {},
        name: '',
      };
    },

    created() {
    },

    methods: {

    },
  };
</script>
