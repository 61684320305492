<template>
  <div class="row">
    <div
      v-for="shift in assignmentshifts"
      :key="shift.id"
      class="col-md-6 mb-4">
      <app-form-shift
        :assignment-shift="shift"
        :assignment-id="Number(shift.assignment_id)"
        @updated="onUpdated" />
    </div>

    <div class="col-md-6 mb-4">
      <div v-if="assignmentshiftFormShow">
        <app-form-shift
          :assignment-id="Number(assignmentId)"
          :toggle-shift-form="showAddAssignmentshiftForm"
          @updated="onUpdated" />
      </div>
      <div
        v-else
        class="card h-100 has-hover"
        @click.prevent="showAddAssignmentshiftForm">
        <div class="card-center-header">
          <i class="material-icons">
            add
          </i>
          <h3>Nieuwe shift toevoegen</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppFormShift from './AppFormShift.vue';
  import api from '../api';

  export default {
    components: { AppFormShift },

    props: {
      assignmentId: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        fields: {},
        assignmentshifts: [],
        assignmentshiftFormShow: false,
      };
    },

    created() {
      this.getAssignmentshifts();
    },

    methods: {
      async getAssignmentshifts() {
        const { data } = await api.getAssignmentshifts(this.assignmentId);
        this.assignmentshifts = data;
        this.$forceUpdate();
      },

      showAddAssignmentshiftForm() {
        this.assignmentshiftFormShow = !this.assignmentshiftFormShow;
      },

      onUpdated() {
        this.assignmentshiftFormShow = false;
        this.getAssignmentshifts();
      },
    },
  };
</script>
