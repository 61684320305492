<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>
          Opdrachtgever toevoegen
        </h2>
        <app-form
          :submit-form="createClient"
          :errors="errors"
          :client="client" />
      </div>
    </div>
  </div>
</template>

<script>
  import AppForm from '../components/AppForm.vue';
  import api from '../api';
  import avatarPlaceholder from '../../../helpers';

  export default {
    components: {
      AppForm,
    },
    data() {
      return {
        client: {},
        errors: {},
      };
    },
    methods: {
      async createClient(event) {
        const form = event.target;
        const formData = new FormData(form);

        for (const [key, value] of formData.entries()) {
          this.client[key] = value;
        }

        // File will be added separately to prevent insert [object file] inside the client object
        const file = form.querySelector('input[type="file"').files[0];
        if (file) {
          this.client.logo_src = URL.createObjectURL(file);
        } else {
          this.client.logo_src = null;
        }

        try {
          await api.createClient(formData);

          this.client = {};
          this.client.logo_src = avatarPlaceholder;
          this.errors = {};
          form.reset();

          this.$toast.success('Gebruiker is aangemaakt', 'Ok');

          await this.$router.push({
            name: 'clients.index',
          });
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = error.response.data.errors;
        }
      },
    },
  };
</script>
