<template>
  <div class="assignment-card">
    <h3>{{ assignment.title }}</h3>

    <div class="assignment-card__background">
      <img
        :src="assignment.logo_src"
        :alt="assignment.title"
        class="assignment-card__image">

      <div class="assignment-card__overlay-text">
        <img
          src="/img/map-marker.svg"
          alt="marker"
          class="ac-icon">
        <p class="ml-2 is-white">
          {{ assignment.city }}
        </p>
      </div>
    </div>

    <div class="assignment-card__time">
      <i class="material-icons ac-icon">
        event
      </i>
      <p class="ml-2">
        {{ assignmentshift.start_date }} t/m {{ assignmentshift.end_date }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      assignment: {
        type: Object,
        required: true,
      },

      assignmentshift: {
        type: Object,
        required: true,
      },
    },
  };
</script>
