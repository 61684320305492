<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex align-items-center">
          <h2>Opdrachten</h2>
          <span
            class="btn btn--secondary btn--small ml-auto mb-3 d-block d-md-none"
            @click="hideFiltersMobile = !hideFiltersMobile">
            Filteren
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="assignments"
      class="row">
      <div
        :class="{ 'hide-filters': hideFiltersMobile }"
        class="col-auto sm-fixed-width-350">
        <app-assignment-filters
          :query.sync="query"
          :errors="errors"
          :isLoaded="isLoaded"
          :categories="categories.data"
          @onsubmit="getAssignments" />
      </div>
      <div class="col">
        <div class="row">
          <div
            v-for="assignment in assignments.data"
            :key="assignment.id"
            class="col-lg-6 col-xl-4 mb-5">
            <app-assignment
              :assignment="assignment" />
          </div>
        </div>

        <pagination
          class="page-indicator"
          :data="assignments"
          :show-disabled="true"
          :limit="2"
          @pagination-change-page="changePage" />
      </div>
    </div>
  </div>
</template>

<script>
  import pagination from 'laravel-vue-pagination';
  import api from '../api';
  import AppAssignment from '../../../components/AppAssignment.vue';
  import AppAssignmentFilters from '../components/AppAssignmentFilters.vue';

  export default {
    components: {
      pagination,
      AppAssignment,
      AppAssignmentFilters,
    },
    data() {
      return {
        hideFiltersMobile: true,
        assignments: {},
        query: {
          page: 1,
          radius: '',
          archive: 'future',
          category_id: '',
        },
        errors: {},
        isLoaded: false,
        categories: {
          data: [],
        },
      };
    },
    created() {
      this.getAssignments();
      this.getCategories();
    },
    methods: {
      async getAssignments() {
        this.isLoaded = true;
        const { data } = await api.getAssignments({ params: this.query });
        console.log(data);
        this.assignments = data;
        this.isLoaded = false;
      },
      changePage(page) {
        this.query.page = page;
        this.getAssignments();
      },
      async getCategories() {
        const { data } = await api.getCategories();
        this.categories = data;
      },
    },
  };
</script>
