<template>
  <div class="container">
    <div class="row mb-4">
      <div class="col-md-12">
        <div class="d-flex align-items-center">
          <h2 class="mb-0 mr-4">
            Mijn Profiel
          </h2>
          <router-link
            v-if="client.id"
            :to="{ name: 'clients.edit', params: { clientId: client.id } }"
            class="btn">
            Bewerken
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card text-center">
          <img
            :src="client.logo_src"
            alt="Avatar"
            class="mb-4">
          <h2>
            {{ client.name }}
          </h2>

          <div
            v-if="client.email"
            class="mb-3">
            <strong>E-mailadres:</strong>
            <p>{{ client.email }}</p>
          </div>

          <div
            v-if="client.phone"
            class="mb-3">
            <strong>Telefoonnummer:</strong>
            <p>{{ client.phone }}</p>
          </div>

          <div
            v-if="client.website"
            class="mb-3">
            <strong>Website:</strong>
            <p>{{ client.website }}</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="content">
            <strong>Omschrijving:</strong>
            <p
              v-if="client.description"
              class="pre-formatted">
              {{ client.description }}
            </p>
            <p v-else>
              <em>Please fill out a description...</em>
            </p>
          </div>
        </div>

        <template v-if="client && client.id && client.can_review">
          <reviews
            :model-type="'client'"
            :model-id="client.id" />
        </template>
      </div>
    </div>

    <div class="row">
      <div
        v-for="assignment in assignments"
        :key="assignment.id"
        class="col-md-6 col-lg-3 col-12 mb-3">
        <app-assignment
          :assignment="assignment" />
      </div>
    </div>
  </div>
</template>

<script>
  import Reviews from '@modules/Reviews/components/Reviews.vue';
  import AppAssignment from '@components/AppAssignment.vue';
  import avatarPlaceholder from '../../../helpers';
  import api from '../api';

  export default {
    components: { Reviews, AppAssignment },
    data() {
      return {
        client: {},
        assignments: [],
      };
    },

    async created() {
      const clientId = this.$route.params.clientId;
      const { data } = await api.getClientById(clientId);
      this.client = data.data;

      if (!this.client.logo_src) {
        this.client.logo_src = avatarPlaceholder;
      }

      this.getAssignments();
    },

    methods: {
      async getAssignments() {
        const clientId = this.$route.params.clientId;
        const { data } = await api.getAssignmentsByClient(clientId);
        this.assignments = data.data;
      },
    },
  };
</script>

<style scoped lang="scss">
  img {
    height: 190px;
    object-fit: cover;
  }
</style>
