<template>
  <div>
    <sub-navigation>
      <li
        v-if="this.$root.$data.user.is_client_or_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'clients.index' }"
          class="sub-nav__link">
          Opdrachtgevers
        </router-link>
      </li>

      <li
        v-if="this.$root.user.is_client_or_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'users.index' }"
          class="sub-nav__link">
          Gebruikers
        </router-link>
      </li>

      <li
        v-if="$root.$data.user.is_security"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'assignments.index' }"
          class="sub-nav__link">
          Werk zoeken
        </router-link>
      </li>

      <li
        v-if="$root.$data.user.is_client_or_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'assignments.index' }"
          class="sub-nav__link">
          Mijn opdrachten
        </router-link>
      </li>

      <li
        v-if="this.$root.user.is_admin"
        class="sub-nav__list">
        <router-link
          :to="{ name: 'categories.index' }"
          class="sub-nav__link">
          Categorieën
        </router-link>
      </li>

      <li
        class="sub-nav__list">
        <router-link
          :to="{ name: 'calendar.index' }"
          class="sub-nav__link">
          Kalender
        </router-link>
      </li>

    </sub-navigation>

    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
  import SubNavigation from '../Layout/SubNav.vue';

  export default {
    components: {
      SubNavigation,
    },
  };
</script>
