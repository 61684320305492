<template>
  <div class="assignment-card">
    <h3>{{ assignmentRegistration.assignmentshift.assignment.title }}</h3>

    <div class="assignment-card__background">
      <img
        :src="assignmentRegistration.assignmentshift.assignment.logo_src"
        :alt="assignmentRegistration.assignmentshift.assignment.title"
        class="assignment-card__image">

      <div class="assignment-card__overlay-text">
        <img
          src="/img/map-marker.svg"
          alt="marker"
          class="ac-icon">
        <p class="ml-2 is-white">
          {{ assignmentRegistration.assignmentshift.assignment.city }}
        </p>
      </div>
    </div>

    <p class="mt-3">
      <label>Shift:</label>
      <span>{{ assignmentRegistration.readable_status }}</span>
    </p>

    <div class="assignment-card__time mt-0">
      <i class="material-icons ac-icon">
        event
      </i>
      <p class="ml-2">
        {{ assignmentRegistration.assignmentshift.date_formatted }}
      </p>
    </div>

    <app-registered-boxes
      :current-supervisors="assignmentRegistration.assignmentshift.current_supervisors"
      :max-supervisors="assignmentRegistration.assignmentshift.max_supervisors"
      :current-security-guards="assignmentRegistration.assignmentshift.current_securityguards"
      :max-security-guards="assignmentRegistration.assignmentshift.max_securityguards" />

    <router-link
      v-if="assignmentRegistration.assignmentshift.can_register_hours"
      :to="{ name: 'assignments.registration',
             params: {
               assignmentId: assignmentRegistration.assignmentshift.assignment_id,
               assignmentshiftId: assignmentRegistration.assignmentshift_id
             }
      }"
      class="btn btn--secondary">
      Uren doorgeven
    </router-link>

    <router-link
      tag="a"
      :to="{
        name: 'assignments.show',
        params: {
          assignmentId: assignmentRegistration.assignmentshift.assignment_id
        }
      }"
      :class="[
        !assignmentRegistration.assignmentshift.can_register_hours ?
          'btn btn--secondary' :
          'ml-2 is-gray'
      ]">
      Meer info
    </router-link>
  </div>
</template>

<script>
  import AppRegisteredBoxes from '@components/AppRegisteredBoxes.vue';

  export default {
    components: {
      AppRegisteredBoxes,
    },

    props: {
      assignmentRegistration: {
        type: Object,
        required: true,
      },
    },
  };
</script>
