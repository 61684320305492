import http from '../../http';

export default {
  getAssignments(params) {
    return http.get('dashboard/get-assignments', {
      params,
    });
  },

  getRegistrations(params) {
    return http.get('dashboard/get-registrations', {
      params,
    });
  },
};
