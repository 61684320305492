import View from '../View.vue';

/* eslint-disable */
import { Index, Create, Edit, Show, Registration, EditRegistration } from '../pages/*';
import Checkin from '../pages/Checkin.vue';
/* eslint-enable */

export default [
  {
    path: '/assignments',
    component: View,
    children: [
      {
        path: '',
        name: 'assignments.index',
        component: Index,
      },
      {
        path: 'create',
        name: 'assignments.create',
        component: Create,
      },
      {
        path: ':assignmentId/edit',
        name: 'assignments.edit',
        component: Edit,
      },
      {
        path: ':assignmentId/:tab?',
        name: 'assignments.show',
        component: Show,
      },
      {
        path: 'registration/edit/:registrationId',
        name: 'assignments.registration.edit',
        component: EditRegistration,
      },
      {
        path: 'registration/:assignmentId/:assignmentshiftId',
        name: 'assignments.registration',
        component: Registration,
      },
      {
        path: ':assignmentId/checkin/:uuid',
        name: 'assignments.checkin',
        component: Checkin,
      },
    ],
  },
];
