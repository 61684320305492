<template>
  <div>
    <div
      v-if="!showForm && assignmentShift"
      class="assignment-card p-30">
      <div class="assignment-card__time mt-0">
        <i class="material-icons ac-icon">event</i>
        <p class="ml-2">
          {{ assignmentShift.date_formatted }}, {{ assignmentShift.time_formatted }}
        </p>
      </div>
      <app-registered-boxes
        :current-security-guards="assignmentShift.current_securityguards"
        :max-security-guards="assignmentShift.max_securityguards"
        :current-supervisors="assignmentShift.current_supervisors"
        :max-supervisors="assignmentShift.max_supervisors" />
      <button
        class="btn btn--secondary"
        @click.prevent="openForm">
        Bewerken
      </button>
      <a
        v-if="assignmentShift"
        class="text-danger ml-2 cursor-pointer"
        @click.prevent="deleteAssignmentShift">
        Shift verwijderen
      </a>
    </div>
    <div
      v-if="showForm"
      class="card">
      <div class="card-body">
        <div class="flex-half">
          <div
            :class="{ 'has-errors' : errors.start_datetime }"
            class="form-group">
            <label for="start_date">Startdatum:</label>
            <input
              id="start_date"
              v-model="fields.start_date"
              type="date"
              class="form-control secondary">
          </div>
          <div
            :class="{ 'has-errors' : errors.start_datetime }"
            class="form-group">
            <label for="start_time">Starttijd:</label>
            <input
              id="start_time"
              v-model="fields.start_time"
              required
              type="time"
              class="form-control secondary">
          </div>
        </div>

        <div class="flex-half">
          <div
            :class="{ 'has-errors' : errors.end_datetime }"
            class="form-group">
            <label for="end_date">Einddatum:</label>
            <input
              id="end_date"
              v-model="fields.end_date"
              type="date"
              class="form-control secondary">
          </div>
          <div
            :class="{ 'has-errors' : errors.end_datetime }"
            class="form-group">
            <label for="end_time">Eindtijd:</label>
            <input
              id="end_time"
              v-model="fields.end_time"
              type="time"
              class="form-control secondary">
          </div>
        </div>

        <div class="flex-half">
          <div
            :class="{ 'has-errors' : errors.max_securityguards }"
            class="form-group">
            <label for="max_securityguards">Max. Beveiligers:</label>
            <input
              id="max_securityguards"
              v-model="fields.max_securityguards"
              type="number"
              class="form-control secondary">
          </div>
          <div
            :class="{ 'has-errors' : errors.max_supervisors }"
            class="form-group">
            <label for="max_supervisors">Max. Toezichthouders:</label>
            <input
              id="max_supervisors"
              v-model="fields.max_supervisors"
              type="number"
              class="form-control secondary">
          </div>
        </div>
        <button
          type="button"
          class="btn"
          @click.prevent="submit">
          Toevoegen
        </button>
        <a
          v-if="assignmentShift"
          class="is-gray ml-2 cursor-pointer"
          @click.prevent="closeForm">
          Annuleren
        </a>
        <a
          v-else
          class="is-gray ml-2 cursor-pointer"
          @click.prevent="toggleShiftForm">
          Annuleren
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import AppRegisteredBoxes from '@components/AppRegisteredBoxes.vue';
  import api from '../api';

  export default {
    components: {
      AppRegisteredBoxes,
    },

    props: {
      assignmentShift: {
        type: Object,
        default: () => {},
      },

      toggleShiftForm: {
        type: Function,
        default: null,
      },

      assignmentId: {
        type: Number,
        required: true,
      },
    },

    data() {
      return {
        showForm: true,
        fields: {},
        errors: {},
      };
    },

    created() {
      this.fields.assignment_id = this.assignmentId;

      if (this.assignmentShift) {
        this.fields = this.assignmentShift;
        this.closeForm();
      }
    },

    methods: {
      async submit() {
        const startDateTime = moment(`${this.fields.start_date} ${this.fields.start_time}`, 'YYYY-MM-DD HH:mm')
          .format('YYYY-MM-DD HH:mm:ss');

        const endDateTime = moment(`${this.fields.end_date} ${this.fields.end_time}`, 'YYYY-MM-DD HH:mm')
          .format('YYYY-MM-DD HH:mm:ss');

        this.fields.start_datetime = startDateTime;
        this.fields.end_datetime = endDateTime;

        try {
          await api.updateOrCreateAssignmentshift(this.fields);
          this.errors = {};
          this.$toast.success('Shift is gewijzigd', 'Ok');
          this.$emit('updated');

          if (this.assignmentShift) {
            this.closeForm();
          }
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
          this.errors = error.response.data.errors;
        }
      },

      async deleteAssignmentShift() {
        if (this.assignmentShift) {
          await api.deleteAssignmentshift(this.assignmentShift.id);
          this.$toast.success('Shift is verwijderd', 'Ok');
          this.$emit('updated');
        }
      },

      closeForm() {
        this.showForm = false;
      },

      openForm() {
        this.showForm = true;
      },
    },
  };
</script>
