<template>
  <form @submit.prevent="submitForm($event)">
    <input-element
      :type="'password'"
      :errors="errors.current_password"
      :autocomplete="'current-password'"
      :name="'current_password'">
      Huidig wachtwoord:
    </input-element>

    <input-element
      :type="'password'"
      :errors="errors.password"
      :autocomplete="'new-password'"
      :name="'password'">
      Nieuw wachtwoord:
    </input-element>

    <input-element
      :type="'password'"
      :errors="[]"
      :autocomplete="'new-password'"
      :name="'password_confirmation'">
      Nieuw wachtwoord herhalen:
    </input-element>

    <button
      class="btn mt-2">
      Wachtwoord wijzigen
    </button>
  </form>
</template>

<script>
  import AppInputElement from '@components/form/AppInputElement.vue';

  export default {
    components: {
      inputElement: AppInputElement,
    },
    props: {
      submitForm: {
        type: Function,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
    },
  };
</script>
