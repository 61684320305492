import Vue from 'vue';
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import http from './http';
import App from './App.vue';
import router from './router';

require('./bootstrap');

Vue.prototype.$http = http;

window.user = {};

Vue.use(VueIziToast, {
  position: 'topRight',
});

// Mixins
window.app_name = process.env.MIX_APP_NAME;
window.app_theme = process.env.MIX_APP_THEME;

const app = new Vue({
  components: { App },
  router,
  data() {
    return {
      user: null,
    };
  },
});


app.$mount('#app');
