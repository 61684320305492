<template>
  <div>
    <form @submit.prevent="submit">
      <div class="form-group">
        <label class="w-100">
          Aanmelden als:
        </label>

        <div
          v-if="assignmentshift.can_register_as_guard"
          class="custom-control custom-radio custom-control-inline">
          <input
            id="securitytype-guard"
            v-model="fields.securitytype"
            type="radio"
            value="2"
            class="custom-control-input">
          <label
            class="custom-control-label"
            for="securitytype-guard">
            Beveiliger
          </label>
        </div>

        <div
          v-if="assignmentshift.can_register_as_supervisor"
          class="custom-control custom-radio custom-control-inline">
          <input
            id="securitytype-supervisor"
            v-model="fields.securitytype"
            type="radio"
            value="1"
            class="custom-control-input">
          <label
            class="custom-control-label"
            for="securitytype-supervisor">
            Toezichthouder
          </label>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import api from '../api';

  export default {
    props: {
      assignmentshift: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        fields: {
          securitytype: 2,
        },
        errors: {},
      };
    },

    created() {
      this.initializeFields();
    },

    methods: {
      async submit() {
        if (this.assignmentshift) {
          try {
            await api.createAssignmentregistration(this.assignmentshift.id, this.fields);
            this.$emit('updated');
          } catch (error) {
            this.$toast.error('Er ging iets fout', 'Oops');
            this.errors = error.response.data.errors;
          }
        }
      },

      initializeFields() {
        this.fields = {
          securitytype: 2,
        };

        if (this.assignmentshift) {
          if (this.assignmentshift.can_register_as_guard) {
            this.fields.securitytype = 2;
          } else if (this.assignmentshift.can_register_as_supervisor) {
            this.fields.securitytype = 1;
          }
        }
      },
    },
  };
</script>
