<template>
  <div
    v-if="loaded"
    class="container">
    <div class="row mb-4">
      <div class="col-md-12">
        <div class="d-flex align-items-center">
          <h2 class="mb-0 mr-4">
            Profiel van {{ user.full_name }}
          </h2>
          <router-link
            v-if="user.id && user.can_update"
            :to="{ name: 'users.edit', params: { userId: user.id } }"
            class="btn">
            Bewerken
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="card text-center">
          <img
            :src="user.avatar_src"
            alt="Avatar"
            class="mb-4">
          <h2>
            {{ user.first_name }} {{ user.last_name }}
          </h2>

          <div
            v-if="user.email"
            class="mb-3">
            <strong>E-mailadres:</strong>
            <p>{{ user.email }}</p>
          </div>

          <div
            v-if="isSecurity && user.securityprofile.phone"
            class="mb-3">
            <strong>Telefoonnummer:</strong>
            <p>{{ user.securityprofile.phone }}</p>
          </div>

          <div
            v-if="isSecurity && user.securityprofile.birthdate"
            class="mb-3">
            <strong>Geboortedatum:</strong>
            <p>{{ user.securityprofile.birthdate }}</p>
          </div>

          <div
            v-if="isSecurity && user.securityprofile.body_length"
            class="mb-3">
            <strong>Lengte (cm):</strong>
            <p>{{ user.securityprofile.body_length }}</p>
          </div>

          <div v-if="isSecurity && user.securityprofile">
            <strong>Type:</strong>
            <p>{{ user.securityprofile.readable_securitytype }}</p>
          </div>
        </div>
      </div>

      <div
        v-if="isSecurity"
        class="col">
        <div class="card">
          <div class="flex-half">
            <div>
              <p><strong>Postcode:</strong></p>
              <p>{{ user.securityprofile.zip_code }}</p>
            </div>
            <div>
              <p><strong>Huisnummer:</strong></p>
              <p>{{ user.securityprofile.house_number }}</p>
            </div>
          </div>
          <div class="flex-half mt-2">
            <div>
              <p><strong>Straatnaam:</strong></p>
              <p>{{ user.securityprofile.street }}</p>
            </div>
            <div>
              <p><strong>Stad:</strong></p>
              <p>{{ user.securityprofile.city }}</p>
            </div>
          </div>
        </div>

        <div class="card">
          <p><strong>Post voorkeur:</strong></p>
          <input-checkbox
            :id="'stand-by'"
            :disabled="true"
            :checked="hasPostPreferences(1)">
            Stand-by
          </input-checkbox>
          <input-checkbox
            :id="'visitatie'"
            :disabled="true"
            :checked="hasPostPreferences(2)">
            Visitatie
          </input-checkbox>
          <input-checkbox
            :id="'vaste-post'"
            :disabled="true"
            :checked="hasPostPreferences(3)">
            Vaste post
          </input-checkbox>
        </div>

        <div class="card">
          <div class="grid-one-fourth mb-2">
            <div>
              <p><strong>Maat colbert:</strong></p>
              <p>{{ user.securityprofile.size_jacket }}</p>
            </div>
            <div>
              <p><strong>Maat Pantalon:</strong></p>
              <p>{{ user.securityprofile.size_trousers }}</p>
            </div>
            <div>
              <p><strong>Maat kleding:</strong></p>
              <p>{{ user.securityprofile.size_clothing }}</p>
            </div>
            <div>
              <p><strong>Schoenmaat:</strong></p>
              <p>{{ user.securityprofile.size_shoes }}</p>
            </div>
          </div>
          <div class="grid-half">
            <div>
              <p><strong>In het bezit van Rijbewijs B:</strong></p>
              <input-radio
                :id="'has-license'"
                :disabled="true"
                :checked="hasDriverLicense(1)">
                Ja
              </input-radio>
              <input-radio
                :id="'no-license'"
                :disabled="true"
                :checked="hasDriverLicense(0)">
                Nee
              </input-radio>
            </div>
            <div>
              <p><strong>In het bezit van een auto:</strong></p>
              <input-radio
                :id="'has-card'"
                :disabled="true"
                :checked="hasCar(1)">
                Ja
              </input-radio>
              <input-radio
                :id="'no-car'"
                :disabled="true"
                :checked="hasCar(0)">
                Nee
              </input-radio>
            </div>
          </div>
        </div>

        <h2 v-if="user.securityprofile.securitypasses.length > 0">
          Diploma/Passen
        </h2>
        <div class="grid-half mb-30">
          <div
            v-for="securityPass in user.securityprofile.securitypasses"
            :key="securityPass.id"
            class="card mb-0">
            <div class="mb-2">
              <p><strong>Naam:</strong></p>
              <p>{{ securityPass.name }}</p>
            </div>
            <div class="mb-2">
              <p><strong>Nummer:</strong></p>
              <p>{{ securityPass.number }}</p>
            </div>
            <div class="mb-2">
              <p><strong>Geldig t/m:</strong></p>
              <p>{{ securityPass.expires_at }}</p>
            </div>
            <div>
              <p><strong>Beschrijving:</strong></p>
              <p>{{ securityPass.description ? securityPass.description : '-' }}</p>
            </div>
          </div>
        </div>

        <template v-if="user && user.id && user.can_review">
          <reviews
            :model-type="'user'"
            :model-id="user.id" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import AppInputCheckbox from '@components/form/AppInputCheckbox.vue';
  import AppInputRadio from '@components/form/AppInputRadio.vue';
  import Reviews from '@modules/Reviews/components/Reviews.vue';
  import avatarPlaceholder from '../../../helpers';
  import api from '../api';
  import mixins from '../mixins';


  export default {
    components: {
      InputCheckbox: AppInputCheckbox,
      InputRadio: AppInputRadio,
      Reviews,
    },

    mixins: [mixins],

    data() {
      return {
        user: {},
        loaded: false,
      };
    },

    computed: {
      isSecurity() {
        return this.user.is_security;
      },
    },

    created() {
      this.initialize();
    },

    methods: {
      async initialize() {
        const userId = this.$route.params.userId;
        const { data } = await api.getUserById(userId);
        this.user = data.data;

        if (!this.user.avatar_src) {
          this.user.avatar_src = avatarPlaceholder;
        }

        if (!this.user.securityprofile) {
          this.user.securityprofile = {};
        }

        this.loaded = true;
      },
    },
  };
</script>

<style scoped lang="scss">
  img {
    height: 190px;
    object-fit: cover;
  }
</style>
