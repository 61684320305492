const sizeJackets = [];
for (let i = 34; i <= 70; i += 2) {
  sizeJackets.push(`${i}`);
}

const sizeClothing = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
];

const sizeShoes = [];
for (let i = 36; i <= 52; i += 1) {
  sizeShoes.push(`${i}`);
}

export {
  sizeJackets,
  sizeClothing,
  sizeShoes,
};
