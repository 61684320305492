<template>
  <form @submit.prevent="submitForm">
    <div class="flex-half">
      <app-input-element
        v-model="newUser.first_name"
        :name="'first_name'"
        :errors="errors.first_name">
        Voornaam
      </app-input-element>

      <app-input-element
        v-model="newUser.last_name"
        :name="'last_name'"
        :errors="errors.last_name">
        Achternaam
      </app-input-element>
    </div>


    <app-input-element
      v-model="newUser.email"
      :name="'email'"
      :type="'email'"
      :errors="errors.email">
      Email
    </app-input-element>

    <div
      v-show="this.$root.user.is_admin"
      class="form-group">
      <label for="role">
        Rol:
      </label>
      <select
        id="role"
        v-model="newUser.role"
        name="role"
        class="browser-default custom-select">
        <option
          v-for="role in roles"
          :key="role"
          ref="roleOptions"
          :value="role"
          :selected="role === newUser.role">
          {{ role }}
        </option>
      </select>
    </div>

    <div
      v-show="this.$root.user.is_admin && newUser.role === 'client'"
      class="form-group">
      <label for="client">
        Opdrachtgever:
      </label>
      <select
        id="client"
        v-model="newUser.client_id"
        name="client_id"
        class="browser-default custom-select">
        <option
          v-for="client in clients"
          :key="client.id"
          :value="client.id">
          {{ client.name }}
        </option>
      </select>
    </div>

    <app-input-checkbox
      :id="'send-invitation'"
      ref="checkbox"
      v-model="newUser.send_invitation"
      :checked.sync="newUser.send_invitation">
      Verzend uitnodiging
    </app-input-checkbox>

    <button
      class="btn mt-3"
      type="submit">
      Aanmaken
    </button>
  </form>
</template>

<script>
  import AppInputElement from '@components/form/AppInputElement.vue';
  import AppInputCheckbox from '@components/form/AppInputCheckbox.vue';

  export default {
    components: {
      AppInputElement,
      AppInputCheckbox,
    },
    props: {
      submitForm: {
        type: Function,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
      clients: {
        type: [Object, Array],
        required: true,
      },
      roles: {
        type: [Object, Array],
        required: true,
      },
      newUser: {
        type: Object,
        required: true,
      },
    },
  };
</script>
