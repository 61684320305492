<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Gebruikers</h2>
        <div class="card">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Role</th>
                <th>Opdrachtgever</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in users.data"
                :key="user.id"
                class="cursor-normal-except-first">
                <router-link
                  tag="td"
                  :to="{ name: 'users.show', params: { userId: user.id } }">
                  {{ user.first_name }} {{ user.last_name }}
                </router-link>

                <td>
                  <div
                    v-if="user.is_security"
                    class="badge badge-success">
                    Beveiliger
                  </div>
                  <div
                    v-if="user.is_client"
                    class="badge badge-success">
                    Opdrachtgever
                  </div>
                  <div
                    v-if="user.is_admin"
                    class="badge badge-success">
                    Admin
                  </div>
                </td>
                <td>{{ user.client_name }}</td>
                <td class="d-flex align-items-center align-icons-rights">
                  <a
                    v-if="!user.activated_at"
                    title="Uitnodiging versturen"
                    @click.prevent="inviteUser(user.id)">
                    <i class="material-icons">
                      send
                    </i>
                  </a>

                  <a
                    v-if="user.can_delete"
                    class="ml-2"
                    title="Verwijderen"
                    @click.prevent="deleteUser(user.id)">
                    <i class="material-icons">
                      delete_outline
                    </i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <pagination
            class="page-indicator"
            :data="users"
            :show-disabled="true"
            :limit="2"
            @pagination-change-page="changePage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pagination from 'laravel-vue-pagination';
  import api from '../api';

  export default {
    components: {
      pagination,
    },
    data() {
      return {
        users: {},
        query: {
          page: 1,
        },
      };
    },
    created() {
      this.getUsers();
    },
    methods: {
      async getUsers() {
        const { data } = await api.getUsers({ params: this.query });
        this.users = data;
      },
      changePage(page) {
        this.query.page = page;
        this.getUsers();
      },
      async inviteUser(userId) {
        await api.inviteUser(userId);
        this.$toast.success('User has been successfully invited', 'Ok');
      },
      async deleteUser(userId) {
        // eslint-disable-next-line no-restricted-globals,no-alert
        if (confirm('Are you sure you want to delete this user?')) {
          await api.deleteUser(userId);
          this.getUsers();
          this.$toast.success('User has been successfully deleted', 'Ok');
        }
      },
    },
  };
</script>
