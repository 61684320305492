<template>
  <div
    :class="{ 'has-errors' : errors.city }"
    class="form-group">
    <label
      for="address-input"
      :class="{ 'is-white' : isWhiteLabel }">
      Locatie:
    </label>
    <input
      id="address-input"
      type="search"
      :class="inputClass">
  </div>
</template>

<script>
  // import places from 'places.js';

  import axios from 'axios';

  export default {
    props: {
      errors: {
        type: Object,
        default: () => {},
      },
      isWhiteLabel: {
        type: Boolean,
        default: false,
      },
      inputClass: {
        type: String,
        default: 'form-control',
      },
    },

    data() {
      return {
        place: null,
      };
    },

    methods: {
      setVal(val) {
        if (this.place) {
          this.place.setVal(val);
        }
      },

      initPlaces() {
        console.log('init places');

        let autocomplete = new google.maps.places.Autocomplete(document.querySelector('#address-input'));

        autocomplete.setComponentRestrictions({
          country: ['nl'],
        });

        autocomplete.addListener('place_changed', () => {
          const placeResult = autocomplete.getPlace();

          this.$emit('onlocationselect', {
            city: placeResult.name,
            lat: placeResult.geometry.location.lat(),
            lng: placeResult.geometry.location.lng(),
          });
        });



        // const config = {
        //     method: 'get',
        //     url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDw_QYf1iZ-ZADKYsC1hM2EZi5Wt1JTWtc&libraries=places',
        // };

        //   axios(config)
        //     .then((response) => console.log(JSON.stringify(response.data)))
        //     .catch((error) => console.log(error));

        try {

          

          // this.place = places({
          //   appId: 'DVINTMORW3',
          //   apiKey: '9393bd7eed22c39daaafce17b456e737',
          //   container: document.querySelector('#address-input'),
          //   countries: ['nl'],
          //   type: 'city',
          // });

          // this.place.on('change', e => {
          //   this.$emit('onlocationselect', {
          //     city: _.get(e, 'suggestion.name', ''),
          //     lat: _.get(e, 'suggestion.latlng.lat', 0),
          //     lng: _.get(e, 'suggestion.latlng.lng', 0),
          //   });
          // });

          // this.place.on('clear', () => {
          //   this.$emit('onclear');
          // });
        } catch {
          // catch error
        }
      },
    },
  };
</script>
