<template>
  <div class="assignment-card">
    <h3>{{ assignment.title }}</h3>
    <h2 class="mt-3 mb-2">
      +{{ assignment.today_registrations }} aanmeldingen
    </h2>

    <div>
      <p>Deze week: +{{ assignment.this_week_registrations }} aanmeldingen</p>
      <p>Vorige week: +{{ assignment.last_week_registrations }} aanmeldingen</p>
    </div>

    <div class="mt-2">
      <p class="is-bold">
        Vervuld:
      </p>
      <app-registered-boxes
        :current-supervisors="assignment.current_supervisors"
        :current-security-guards="assignment.current_securityguards"
        :max-supervisors="assignment.max_supervisors"
        :max-security-guards="assignment.max_securityguards" />
    </div>

    <div v-if="$root.$data.user.is_client_or_admin">
      <router-link
        tag="a"
        :to="{ name: 'assignments.show', params: {
          assignmentId: assignment.id, tab: 'registrations'
        } }"
        class="btn btn--secondary">
        Aanmeldingen
      </router-link>
    </div>
  </div>
</template>

<script>
  import AppRegisteredBoxes from '@components/AppRegisteredBoxes.vue';

  export default {
    components: {
      AppRegisteredBoxes,
    },

    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },
  };
</script>
