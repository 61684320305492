<template>
  <div>
    <div class="container">
      <template v-if="this.$root.user.is_client_or_admin">
        <app-client-dashboard />
      </template>
      <template v-else-if="this.$root.user.is_security">
        <app-security-dashboard />
      </template>
    </div>
  </div>
</template>

<script>
  import AppClientDashboard from '@modules/Dashboard/components/AppClientDashboard.vue';
  import AppSecurityDashboard from '@modules/Dashboard/components/AppSecurityDashboard.vue';

  export default {
    components: { AppClientDashboard, AppSecurityDashboard },
  };
</script>
