var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},_vm._l((_vm.registrations),function(registration){return _c('div',{key:registration.id,staticClass:"col-md-12 mt-3"},[_c('div',{staticClass:"hour-validation-card"},[_c('div',{staticClass:"d-flex h-100"},[_c('div',{staticClass:"hour-validation-card__avatar"},[_c('img',{attrs:{"src":registration.user.avatar_src,"alt":"avatar"}})]),_vm._v(" "),_c('div',{staticClass:"hour-validation-card__person-info ml-3"},[_c('h3',[_vm._v(_vm._s(registration.user.full_name))]),_vm._v(" "),_c('div',{staticClass:"flex-center"},[(registration.securitytype === 1)?_c('i',{staticClass:"material-icons ac-icon"},[_vm._v("\n              remove_red_eye\n            ")]):_vm._e(),_vm._v(" "),(registration.securitytype === 2)?_c('img',{staticClass:"ac-icon",attrs:{"src":"/img/ic-security.svg","alt":"icon"}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"ml-1"},[_vm._v("\n              "+_vm._s(registration.readable_securitytype)+"\n            ")])]),_vm._v(" "),_c('router-link',{staticClass:"btn btn--secondary btn--small",attrs:{"to":{ name: 'users.show', params: { userId: registration.user.id }}}},[_vm._v("\n            Review geven\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"hour-validation-card__hours mt-2 mt-sm-0 ml-sm-auto"},[_c('div',{staticClass:"hour-validation-card__time"},[_c('p',[_vm._v("Van")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(registration.start_time))])]),_vm._v(" "),_c('div',{staticClass:"hour-validation-card__time"},[_c('p',[_vm._v("Tot")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(registration.end_time))])]),_vm._v(" "),_c('div',{staticClass:"hour-validation-card__time"},[_c('p',[_vm._v("totaal")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(registration.total_hours))])])]),_vm._v(" "),_c('div',{staticClass:"d-flex ml-sm-5"},[_c('div',{staticClass:"flex-center mt-2"},[_c('a',{staticClass:"assignment-card__box btn-box success",class:{
              'accepted' : registration.status === 7,
              'no-events' : registration.status > 5
            },on:{"click":function($event){$event.preventDefault();return _vm.approveHours(registration.id)}}},[_c('i',{staticClass:"material-icons ac-icon"},[_vm._v("\n              done\n            ")])]),_vm._v(" "),_c('a',{staticClass:"assignment-card__box btn-box danger",class:{
              'declined' : registration.status === 6,
              'no-events' : registration.status > 5
            },on:{"click":function($event){$event.preventDefault();return _vm.declineHours(registration.id)}}},[_c('i',{staticClass:"material-icons ac-icon"},[_vm._v("\n              close\n            ")])])])]),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column ml-sm-3"},[(registration.can_update)?_c('router-link',{staticClass:"btn btn--secondary mt-3 mt-sm-0 mb-3",attrs:{"tag":"a","target":"_blank","to":{
            name: 'assignments.registration.edit',
            params: { registrationId: registration.id }
          }}},[_vm._v("\n          Bewerken\n        ")]):_vm._e(),_vm._v(" "),(registration.briefing_src !== '')?_c('a',{staticClass:"btn btn--secondary",attrs:{"href":registration.briefing_src,"target":"_blank"}},[_vm._v("\n          Werkbrief downloaden\n        ")]):_vm._e()],1)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }