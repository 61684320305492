import Promise from 'lodash/_Promise';
import http from '../../http';

export default {
  getAssignments(params) {
    return http.get('assignments', params);
  },
  getAssignmentById(id) {
    return http.get(`assignments/${id}`);
  },
  getAssignmentsByClient(clientId) {
    return http.get(`clients/${clientId}/assignments`);
  },
  getSimilarAssignments(id) {
    return http.get(`assignments/${id}/similar`);
  },
  updateAssignmentById(id, params) {
    return http.post(`assignments/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  createAssignment(params) {
    return http.post('assignments', params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deleteAssignment(assignmentId) {
    return http.delete(`assignments/${assignmentId}`);
  },
  getAssignmentshift(assignmentId, assignmentshiftId) {
    return http.get(`assignmentshifts/${assignmentId}/${assignmentshiftId}`);
  },
  getUserAssignmentShifts(assignmentId) {
    return http.get(`user/shifts/${assignmentId}`);
  },
  exportHours(id) {
    return http.get(`assignmentshifts/export-hours/${id}`);
  },

  getAssignmentshifts(assignmentId) {
    return http.get(`assignmentshifts/${assignmentId}`);
  },
  updateOrCreateAssignmentshift(params) {
    return http.post(`assignmentshifts`, params);
  },
  updateAssignmentshiftReport(id, params) {
    return http.post(`assignmentshifts/update-report/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  updateAssignmentshiftUserReport(id, params) {
    return http.post(`assignmentshifts/update-user-report/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  deletePersonalUserReport(id) {
    return http.delete(`user/delete-personal-user-report/${id}`);
  },
  getPersonalUserReports(assignmentshiftId) {
    return http.get(`user/get-personal-user-reports/${assignmentshiftId}`);
  },
  getAllUserReports(assignmentshiftId) {
    return http.get(`assignmentshiftreports/get-all-user-reports/${assignmentshiftId}`);
  },
  deleteAssignmentshift(id) {
    return http.delete(`assignmentshifts/${id}`);
  },
  createAssignmentregistration(assignmentshiftId, params) {
    return http.post(`assignmentregistrations/${assignmentshiftId}`, params);
  },
  deleteAssignmentregistration(assignmentshiftId) {
    return http.delete(`assignmentregistrations/${assignmentshiftId}`);
  },
  getRegistrationsByShift(assignmentshiftId, params) {
    return http.get(`assignmentregistrations/${assignmentshiftId}`, { params });
  },
  updateRegistrationStatus(assignmentregistrationId, assignmentshiftId, status) {
    return http.post(`assignmentregistrations/status/${assignmentregistrationId}/${assignmentshiftId}/${status}`);
  },
  updateRegistrationStatuses(assignmentshiftId, query, status) {
    return http.post(`assignmentregistrations/statuses/${assignmentshiftId}/${status}`, query);
  },
  registerHoursByShift(assignmentshiftId, params) {
    return http.post(`assignmentregistrations/registerHours/${assignmentshiftId}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getCategories() {
    return http.get(`categories/get-available-categories`);
  },

  getRegistration(id) {
    return http.get(`assignmentregistrations/get/${id}`);
  },

  updateRegistration(id, params) {
    return http.post(`assignmentregistrations/update/${id}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  checkin(uuid) {
    return http.post(`assignments/checkin/${uuid}`);
  },

  getCheckins(assignmentId) {
    return http.get(`assignments/checkins/${assignmentId}`);
  },

  exportShiftCheckins(assignmentShiftId) {
    return http.get(`assignments/checkins/export/${assignmentShiftId}`, {
      responseType: 'blob',
    });
  },
};
