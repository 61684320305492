<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12">
        <h2 class="page-title">Checkin</h2>
        <div v-if="checkinSuccess !== null">
          <p v-if="!checkinSuccess" class="alert alert-danger">{{ errorMessage }}</p>
          <p v-if="checkinSuccess" class="alert alert-success">Je bent successvol ingecheckt.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import avatarPlaceholder from '../../../helpers';
  import api from '../api';

  export default {
    components: {
    },
    data() {
      return {
        assignment: {},
        currentTab: 'show',
        loaded: false,
        checkinSuccess: null,
        errorMessage: '',
      };
    },

    async created() {
      if (this.$route.params.tab) {
        this.currentTab = this.$route.params.tab;
      }

      this.getAssignment();
      this.checkin();
    },

    methods: {
      async getAssignment() {
        const assignmentId = this.$route.params.assignmentId;
        const { data } = await api.getAssignmentById(assignmentId);
        this.assignment = data.data;

        if (!this.assignment.logo_src) {
          this.assignment.logo_src = avatarPlaceholder;
        }

        this.loaded = true;
      },

      async checkin() {
        const uuid = this.$route.params.uuid;
        const response = await api.checkin(uuid);
        const data = response.data;
        if (data.success && data.success === true) {
          this.checkinSuccess = true;
        } else {
          this.checkinSuccess = false;
          this.errorMessage = data.message;
          console.log(data.message);
        }

        if (!this.assignment.logo_src) {
          this.assignment.logo_src = avatarPlaceholder;
        }
      },

      changeTab(tab) {
        this.currentTab = tab;
        this.$router.push({ name: 'assignments.show', params: { tab } });

        this.getAssignment();
      },
    },
  };
</script>
