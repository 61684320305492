<template>
  <form
    ref="form"
    @submit.prevent="submitForm($event)">
    <app-avatar-upload :src="client.logo_src" />

    <div
      class="form-group"
      :class="{ 'has-errors': errors.name }">
      <label for="company-name">
        Bedrijfsnaam:
      </label>
      <input
        id="company-name"
        name="name"
        type="text"
        maxlength="32"
        class="form-control"
        :value="client.name">
      <app-form-errors :input-errors="errors.name" />
    </div>

    <div
      class="form-group"
      :class="{ 'has-errors': errors.email }">
      <label for="email">
        E-mailadres:
      </label>
      <input
        id="email"
        name="email"
        type="email"
        maxlength="64"
        class="form-control"
        :value="client.email">
      <app-form-errors :input-errors="errors.email" />
    </div>

    <div
      class="form-group"
      :class="{ 'has-errors': errors.phone }">
      <label for="phone">
        Telefoonnummer:
      </label>
      <input
        id="phone"
        name="phone"
        type="text"
        maxlength="64"
        class="form-control"
        :value="client.phone">
      <app-form-errors :input-errors="errors.phone" />
    </div>

    <div
      class="form-group"
      :class="{ 'has-errors': errors.website }">
      <label for="website">
        Website:
      </label>
      <input
        id="website"
        name="website"
        type="text"
        maxlength="64"
        class="form-control"
        :value="client.website">
      <app-form-errors :input-errors="errors.website" />
    </div>

    <div
      class="form-group"
      :class="{ 'has-errors': errors.description }">
      <label for="description">
        Omschrijving:
      </label>
      <textarea
        id="description"
        name="description"
        class="form-control"
        maxlength="1000"
        v-text="client.description">
      </textarea>
      <app-form-errors :input-errors="errors.description" />
    </div>

    <button
      class="btn mt-2">
      Opslaan
    </button>
  </form>
</template>

<script>
  import AppAvatarUpload from './AppAvatarUpload.vue';
  import AppFormErrors from '../../../components/form/AppFormErrors.vue';

  export default {
    components: {
      AppAvatarUpload,
      AppFormErrors,
    },
    props: {
      submitForm: {
        type: Function,
        required: true,
      },
      errors: {
        type: Object,
        required: true,
      },
      client: {
        type: Object,
        required: true,
      },
    },
  };
</script>
