<template>
  <div>
    <div class="container mt-3 mb-3">
      <div class="row">
        <div class="col-md-3">
          <div class="list-group">
            <a
              v-for="shift in assignment.assignmentshifts"
              :key="shift.id"
              class="list-group-item list-group-item-action"
              :class="{ 'active': currentShift && currentShift.id === shift.id }"
              href="#"
              @click.prevent="setCurrentShift(shift)">
              <div>
                <p class="is-bold">{{ shift.date_formatted }}</p>
                <div class="flex-center mt-2 mb-3">
                  <i class="material-icons ac-icon big mr-2">
                    access_time
                  </i>
                  <p>{{ shift.time_formatted }}</p>
                </div>

                <div class="flex-center mt-2 mb-3">
                  <div class="assignment-card__box">
                    <i class="material-icons ac-icon">
                      remove_red_eye
                    </i>
                    <p class="ml-1">
                      {{ shift.current_supervisors }}/{{ shift.max_supervisors }}
                    </p>
                  </div>
                  <div class="assignment-card__box">
                    <img
                      src="/img/ic-security.svg"
                      alt="icon"
                      class="ac-icon">
                    <p class="ml-1">
                      {{ shift.current_securityguards }}/{{ shift.max_securityguards }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <form @submit.prevent="getRegistrations">
                <div><strong>Filteren:</strong></div>

                <div class="form-group">
                  <label>Status:</label>

                  <div class="custom-control custom-checkbox">
                    <input
                      id="state-pending"
                      v-model="query.state"
                      type="checkbox"
                      value="1"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="state-pending">
                      In afwachting
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      id="state-accepted"
                      v-model="query.state"
                      type="checkbox"
                      value="3"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="state-accepted">
                      Geaccepteerd
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      id="state-denied"
                      v-model="query.state"
                      value="2"
                      type="checkbox"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="state-denied">
                      Afgewezen
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label>Type:</label>

                  <div class="custom-control custom-checkbox">
                    <input
                      id="type-supervisor"
                      v-model="query.securitytypes"
                      type="checkbox"
                      value="1"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="type-supervisor">
                      Toezichthouder
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      id="type-guard"
                      v-model="query.securitytypes"
                      type="checkbox"
                      value="2"
                      class="custom-control-input">
                    <label
                      class="custom-control-label"
                      for="type-guard">
                      Beveiliger
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="name-search">Naam:</label>
                  <input
                    id="name-search"
                    v-model="query.search"
                    type="text"
                    placeholder="Zoek op naam"
                    class="form-control secondary">
                </div>

                <button
                  type="submit"
                  class="btn">
                  Filters toepassen
                </button>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4 ml-auto">
              <div class="form-group">
                <select
                  v-model="query.sort"
                  class="browser-default custom-select"
                  @change="getRegistrations">
                  <option value="">
                    Sorteren op: Nieuwste
                  </option>
                  <option value="oldest">
                    Sorteren op: Oudste
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div
            v-if="!registrations ||
              !registrations.data || Object.keys(registrations.data).length < 1"
            class="alert alert-info">
            Geen resultaten
          </div>

          <div
            v-if="registrations &&
              registrations.data && Object.keys(registrations.data).length > 0">
            <div class="row">
              <div
                v-for="registration in registrations.data"
                :key="registration.id"
                class="col-md-3">
                <div
                  class="assignment-card has-hover"
                  :class="{
                    'accepted' : registration.status >= 3,
                    'declined' : registration.status === 2
                  }">
                  <div class="assignment-card__avatar">
                    <img
                      :src="registration.user.avatar_src"
                      alt="avatar"
                      class="assignment-card__image">
                  </div>

                  <h3>{{ registration.user.full_name }}</h3>

                  <p>
                    <strong>Lengte:</strong>
                    <span>{{ registration.user.securityprofile.body_length / 100 }} m</span>
                  </p>

                  <p>
                    <strong>Leeftijd:</strong>
                    <span>{{ getAge(registration.user.securityprofile.birthdate) }}</span>
                  </p>

                  <div>
                    <strong>Voorkeur:</strong>
                    <div>
                      <span
                        v-for="postPreference in registration.user.securityprofile.postpreferences"
                        :key="postPreference.id">
                        {{ postPreference.name }}
                      </span>
                    </div>
                  </div>

                  <!--                  <div>Status: {{ registration.readable_status }}</div>-->

                  <div class="flex-center mt-2 mb-3">
                    <i
                      v-if="registration.securitytype === 1"
                      class="material-icons ac-icon">
                      remove_red_eye
                    </i>
                    <img
                      v-if="registration.securitytype === 2"
                      src="/img/ic-security.svg"
                      alt="icon"
                      class="ac-icon">
                    <p class="ml-1">
                      {{ registration.readable_securitytype }}
                    </p>
                  </div>

                  <div class="flex-center mt-2">
                    <a
                      class="assignment-card__box btn-box success"
                      :class="{ 'accepted' : registration.status >= 3 }"
                      @click.prevent="approve(registration.id)">
                      <i class="material-icons ac-icon">
                        done
                      </i>
                    </a>
                    <a
                      class="assignment-card__box btn-box danger"
                      :class="{ 'declined' : registration.status === 2 }"
                      @click.prevent="deny(registration.id)">
                      <i class="material-icons ac-icon">
                        close
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <pagination
                class="page-indicator"
                :data="registrations"
                :show-disabled="true"
                :limit="2"
                @pagination-change-page="changePage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pagination from 'laravel-vue-pagination';
  import defaultMixin from '@/mixins';
  import api from '../../api';

  export default {
    components: { pagination },

    mixins: [
      defaultMixin,
    ],

    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },


    data() {
      return {
        currentShift: {},
        registrations: [],
        query: {
          sort: '',
          page: 1,
          search: '',
          state: [
            1,
            2,
            3,
          ],
          securitytypes: [
            1,
            2,
          ],
        },
      };
    },

    created() {
      this.initSetCurrentShift();
    },

    methods: {
      changePage(page) {
        this.query.page = page;
        this.getRegistrations();
      },

      initSetCurrentShift() {
        if (this.currentShift && Object.keys(this.currentShift).length > 0) {
          this.setCurrentShift(_.find(this.assignment.assignmentshifts, {
            id: this.currentShift.id,
          }));
        } else {
          this.setCurrentShift(_.head(this.assignment.assignmentshifts) || {});
        }
      },

      async getRegistrations() {
        if (this.currentShift && this.currentShift.id) {
          const { data } = await api.getRegistrationsByShift(this.currentShift.id, this.query);
          this.registrations = data;
        }
      },

      async setCurrentShift(shift) {
        this.currentShift = shift;
        this.getRegistrations();
      },

      async updateRegistrationStatus(assignmentregistrationId, status) {
        if (this.currentShift && this.currentShift.id) {
          await api.updateRegistrationStatus(
            assignmentregistrationId,
            this.currentShift.id,
            status,
          );
          this.getRegistrations();
          this.$emit('updateassignment');
        }
      },

      async approve(assignmentregistrationId) {
        this.updateRegistrationStatus(assignmentregistrationId, 3);
      },

      async deny(assignmentregistrationId) {
        this.updateRegistrationStatus(assignmentregistrationId, 2);
      },
    },
  };
</script>
