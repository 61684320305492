<template>
  <div class="form-group d-flex align-items-start mb-5 flex-column flex-sm-row">
    <div class="avatar-upload mr-3">
      <img
        :src="url"
        alt="Avatar">
    </div>

    <div class="d-flex flex-column">
      <input
        ref="avatarFileInput"
        accept="image/*"
        type="file"
        class="d-none"
        :name="name"
        @change="uploadedAvatar">

      <button
        class="btn mt-3 mt-sm-0"
        type="button"
        @click="toggleFileInput">
        {{ text }} uploaden
      </button>

      <button
        class="btn btn--secondary mt-3"
        type="button"
        @click="removeFileInput">
        {{ text }} verwijderen
      </button>
    </div>
  </div>
</template>

<script>
  import avatarPlaceholder from '../../../helpers';

  export default {
    props: {
      src: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: 'Logo',
      },
      name: {
        type: String,
        default: 'logo',
      },
    },
    data() {
      return {
        url: avatarPlaceholder,
      };
    },
    watch: {
      src(value) {
        this.url = value;
      },
    },
    created() {
      if (this.src) {
        this.url = this.src;
      }
    },
    methods: {
      toggleFileInput() {
        this.$refs.avatarFileInput.click();
      },
      uploadedAvatar(event) {
        const file = event.target.files[0];
        this.url = URL.createObjectURL(file);
        this.$emit('input', file);
      },
      removeFileInput() {
        this.$refs.avatarFileInput.value = '';
        this.url = avatarPlaceholder;
      },
    },
  };
</script>
