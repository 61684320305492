<template>
  <div class="card">
    <input-element
      v-model="securityPass.name"
      :name="`securitypasses[${index}][name]`"
      :errors="errors[`securitypasses.${index}.name`]"
      :secondary-style="true">
      Naam:
    </input-element>

    <input-element
      v-model="securityPass.number"
      :name="`securitypasses[${index}][number]`"
      :errors="errors[`securitypasses.${index}.number`]"
      :secondary-style="true">
      Nummer:
    </input-element>

    <input-element
      v-model="securityPass.expires_at"
      :type="'date'"
      :name="`securitypasses[${index}][expires_at]`"
      :errors="[]"
      :secondary-style="true">
      Geldig t/m:
    </input-element>

    <input-element
      v-model="securityPass.description"
      :name="`securitypasses[${index}][description]`"
      :errors="[]"
      :secondary-style="true"
      :show-length-indicator="true"
      :max-length="200">
      Beschrijving:
    </input-element>

    <span
      class="d-flex align-items-center cursor-pointer"
      @click.prevent="removeThis(index)">
      <i class="material-icons mr-2">
        delete_forever
      </i>
      Diploma / pas verwijderen
    </span>
  </div>
</template>

<script>
  import AppInputElement from '@components/form/AppInputElement.vue';

  export default {
    components: {
      InputElement: AppInputElement,
    },
    props: {
      index: {
        type: Number,
        default: null,
      },
      securityPass: {
        type: Object,
        default: () => {},
      },
      errors: {
        type: [Array, Object],
        default: () => [],
      },
    },
    methods: {
      removeThis(index) {
        this.$emit('removeItem', index);
      },
    },
  };
</script>
