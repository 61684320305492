<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <p class="is-bold">
              Opdrachtomschrijving:
            </p>
            <p class="pre-formatted">
              {{ assignment.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <p class="is-bold">
              Organisator:
            </p>
            <p>{{ assignment.organizer }}</p>

            <p class="is-bold mt-2">
              Opdrachtgever:
            </p>
            <p>{{ assignment.client_name }}</p>

            <p class="is-bold mt-2">
              Minimumtarief:
            </p>
            <p>€{{ assignment.minimum_rate }}</p>
            <p class="is-bold mt-2">
              Functies bezet:
            </p>
            <app-registered-boxes
              :current-security-guards="assignment.current_securityguards"
              :max-security-guards="assignment.max_securityguards"
              :current-supervisors="assignment.current_supervisors"
              :max-supervisors="assignment.max_supervisors" />
          </div>
        </div>

        <div
          v-if="assignment.briefing_src"
          class="card mt-2">
          <div class="card-body">
            <p class="is-bold">
              Download opdrachtbriefing:
            </p>
            <a
              class="is-default flex-center"
              target="_blank"
              :href="assignment.briefing_src">
              <i class="material-icons ac-icon mr-1">
                attach_file
              </i>
              {{ assignment.briefing_name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="assignment.assignmentshifts"
      class="row mt-3">
      <div class="col-md-12">
        <h2>Shifts:</h2>
      </div>
      <div
        v-for="assignmentshift in assignment.assignmentshifts"
        :key="assignmentshift.id"
        class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div
              v-if="assignmentshift.is_registered"
              class="alert alert-success">
              Je hebt je ingeschreven voor deze shift!
            </div>

            <div class="flex-center">
              <i class="material-icons ac-icon big mr-2">
                access_time
              </i>
              <strong>
                {{ assignmentshift.date_formatted }}, {{ assignmentshift.time_formatted }}
              </strong>
            </div>

            <app-registered-boxes
              :current-security-guards="assignmentshift.current_securityguards"
              :max-security-guards="assignmentshift.max_securityguards"
              :current-supervisors="assignmentshift.current_supervisors"
              :max-supervisors="assignmentshift.max_supervisors" />

            <a
              v-if="assignmentshift.can_register"
              class="btn btn--secondary"
              href="#"
              @click.prevent="signup(assignmentshift)">
              Inschrijven
            </a>

            <router-link
              v-if="assignmentshift.can_register_hours"
              :to="{ name: 'assignments.registration',
                     params: {
                       assignmentId: assignment.id,
                       assignmentshiftId: assignmentshift.id
                     }
              }"
              class="btn btn--secondary">
              Uren doorgeven
            </router-link>


            <a
              v-if="assignmentshift.can_unregister"
              class="btn btn--secondary"
              href="#"
              @click.prevent="signout(assignmentshift)">
              Uitschrijven
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <h2>Opdrachten van deze opdrachtgever:</h2>
      </div>
      <div
        v-for="as1 in assignments"
        :key="as1.id"
        class="col-md-6 col-lg-3 col-12 mb-3">
        <app-assignment
          :assignment="as1" />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-md-12">
        <h2>Vergelijkbare opdrachten:</h2>
      </div>
      <div
        v-for="as2 in similarAssignments"
        :key="as2.id"
        class="col-md-6 col-lg-3 col-12 mb-3">
        <app-assignment
          :assignment="as2" />
      </div>
    </div>

    <div
      id="assignmentshiftRegistrationModal"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="assignmentshiftRegistrationModalLabel"
      aria-hidden="true">
      <div
        class="modal-dialog"
        role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="assignmentshiftRegistrationModalLabel"
              class="modal-title">
              Inschrijven op shift
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-registration-form
              v-if="currentAssignmentshift"
              ref="assignmentregistrationForm"
              :assignmentshift="currentAssignmentshift"
              @updated="closeAssignmentregistrationForm" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn--secondary"
              data-dismiss="modal">
              Sluiten
            </button>
            <button
              v-if="currentAssignmentshift"
              type="submit"
              class="btn"
              @click.prevent="submitAssignmentregistrationForm">
              Inschrijven
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppRegisteredBoxes from '@components/AppRegisteredBoxes.vue';
  import AppAssignment from '@components/AppAssignment.vue';
  import api from '../../api';
  import AppRegistrationForm from '../AppRegistrationForm.vue';

  export default {
    components: {
      AppRegistrationForm,
      AppRegisteredBoxes,
      AppAssignment,
    },

    props: {
      assignment: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        currentAssignmentshift: null,
        assignments: [],
        similarAssignments: [],
      };
    },

    created() {
      this.getAssignments();
      this.getSimilarAssignments();
    },

    methods: {
      submitAssignmentregistrationForm() {
        if (this.$refs.assignmentregistrationForm) {
          this.$refs.assignmentregistrationForm.submit();
        }
      },

      closeAssignmentregistrationForm() {
        $('#assignmentshiftRegistrationModal').modal('hide');
        this.currentAssignmentshift = null;
        this.$emit('updateassignment');
      },

      async signup(assignmentshift) {
        this.currentAssignmentshift = assignmentshift;
        this.$forceUpdate();

        if (this.$refs.assignmentregistrationForm) {
          this.$refs.assignmentregistrationForm.initializeFields();
        }

        $('#assignmentshiftRegistrationModal').modal('show');
      },

      async signout(assignmentshift) {
        try {
          await api.deleteAssignmentregistration(assignmentshift.id);
          this.$emit('updateassignment');
        } catch (error) {
          this.$toast.error('Er ging iets fout', 'Oops');
        }
      },

      async getAssignments() {
        if (this.assignment && this.assignment.client_id) {
          const { data } = await api.getAssignmentsByClient(this.assignment.client_id);
          this.assignments = data.data;
        } else {
          this.assignments = [];
        }
      },

      async getSimilarAssignments() {
        if (this.assignment && this.assignment.id) {
          const { data } = await api.getSimilarAssignments(this.assignment.id);
          this.similarAssignments = data.data;
        } else {
          this.similarAssignments = [];
        }
      },

      openUploads() {
        this.$router.push({
          name: 'assignments.uploads',
          params: {
            assignmentId: this.assignment.id,
          },
        });
      },
    },
  };
</script>
