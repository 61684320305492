import http from '../../http';

export default {
  getClients(params) {
    return http.get('clients', params);
  },
  getClientById(clientId) {
    return http.get(`clients/${clientId}`);
  },
  updateClientById(clientId, params) {
    return http.post(`clients/${clientId}`, params);
  },
  createClient(params) {
    return http.post('clients', params);
  },
  getAssignmentsByClient(clientId) {
    return http.get(`clients/${clientId}/assignments`);
  },
};
