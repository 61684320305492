<template>
  <div class="card">
    <div class="card-body">
      <h2>Reviews</h2>
      <template v-if="reviews && reviews.data && Object.keys(reviews.data).length > 0">
        <div
          v-for="review in reviews.data"
          :key="review.id"
          class="mb-5">
          <p class="is-bold">
            Review {{ review.assignment_name }}
          </p>
          <p>Shift: {{ review.assignmentshift_name }}</p>
          <a
            v-for="i in 5"
            :key="i"
            class="mt-2">
            <i class="material-icons is-gold cursor-pointer">
              {{ (i > review.stars) ? 'star_border' : 'star' }}
            </i>
          </a>

          <a
            v-if="review.can_delete"
            href="#"
            class="text-danger d-block small mt-1 mb-1 float-right"
            @click.prevent="deleteReview(review.id)">Verwijderen</a>

          <div class="mt-2">
            <p>
              <span class="d-block font-weight-bold">Toelichting:</span>
              {{ review.comment }}
            </p>
          </div>
        </div>

        <div class="mb-5">
          <pagination
            class="page-indicator"
            :data="reviews"
            :show-disabled="true"
            :limit="2"
            @pagination-change-page="changePage" />
        </div>
      </template>

      <create-review
        :model-id="modelId"
        :model-type="modelType"
        @created="changePage(1)" />
    </div>
  </div>
</template>

<script>
  import CreateReview from '@modules/Reviews/components/CreateReview.vue';
  import pagination from 'laravel-vue-pagination';
  import api from '../api';

  export default {
    components: { CreateReview, pagination },
    props: {
      modelId: {
        type: Number,
        required: true,
      },

      modelType: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        reviews: {},
        page: 1,
      };
    },

    created() {
      this.getReviews();
    },

    methods: {
      async getReviews() {
        const { data } = await api.getReviews({
          model_id: this.modelId,
          model_type: this.modelType,
          page: this.page,
        });

        this.reviews = data;
      },

      changePage(page) {
        this.page = page;
        this.getReviews();
      },

      async deleteReview(id) {
        // eslint-disable-next-line no-restricted-globals,no-alert
        if (confirm('Verwijder de review?')) {
          await api.deleteReview(id);
          this.$toast.success('Review is verwijderd', 'Ok');
          this.changePage(1);
        }
      },
    },
  };
</script>
