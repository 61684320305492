<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Opdrachtgevers</h2>
        <div class="card">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Bedrijfsnaam</th>
                <th>Aantal gebruikers</th>
              </tr>
            </thead>
            <tbody>
              <router-link
                v-for="client in clients.data"
                :key="client.id"
                tag="tr"
                :to="{ name: 'clients.show', params: { clientId: client.id } }">
                <td>{{ client.name }}</td>
                <td>{{ client.amount_of_users }}</td>
              </router-link>
            </tbody>
          </table>

          <pagination
            class="page-indicator"
            :data="clients"
            :show-disabled="true"
            :limit="2"
            @pagination-change-page="changePage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pagination from 'laravel-vue-pagination';
  import api from '../api';

  export default {
    components: {
      pagination,
    },
    data() {
      return {
        clients: {},
        query: {
          page: 1,
        },
      };
    },
    created() {
      this.getClients();
    },
    methods: {
      async getClients() {
        const { data } = await api.getClients({ params: this.query });
        this.clients = data;
      },
      changePage(page) {
        this.query.page = page;
        this.getClients();
      },
    },
  };
</script>
